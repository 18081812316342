import React, { useState, useEffect } from 'react';
import {
  Button, Box, Typography, InputAdornment,
  Dialog, DialogTitle,DialogContent, DialogContentText, DialogActions,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import FormTextField from '../../utility/forms/FormTextField';
import FormSelect from '../../utility/forms/FormSelect';
import FormSwitch from '../../utility/forms/FormSwitch';
import { createOrder, patchOrder } from '../../feathers/services/Order.service';
import { findClients } from '../../feathers/services/User.service';
import { Order, User } from '../../feathers/server-interface';

export interface OrderFormInt {
  isOpen: boolean;
  order: Order | undefined;
}

export interface OrderFormInterface {
  orderForm: OrderFormInt;
  setOrderForm: React.Dispatch<React.SetStateAction<OrderFormInt>>;
}

const OrderForm: React.FC<OrderFormInterface> = ({
  orderForm,
  setOrderForm,
}) => {
  const { isOpen, order } = orderForm;
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [clientOptions, setClientOptions] = useState<{ value: string; label: string; }[]>([]);
  // const clientOptions = clients.map((client) => ({ value: client._id, label: client.username }));

  useEffect(() => {
    const init = async () => {
      if (isOpen) {
        try {
          const foundClients = await findClients('');
          setClientOptions(foundClients.map((client) => ({ value: client._id, label: client.username })));
        } catch (error: any) {
          setErrorMessage(error.message);
        }
      }
    };
    init();
  }, [isOpen]);

  const handleClose = () => {
    setOrderForm({
      isOpen: false,
      order: undefined,
    });
  };

  const schema = Yup.object().shape({
    name: Yup.string().required('Order name is required'),
    client: Yup.string().required('Client is required'),
  });
  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth>
      <DialogTitle>{!order ? 'Create Order' : `Edit Order`}</DialogTitle>
      <Formik
        enableReinitialize
        validationSchema={schema}
        initialValues={{
          client: order ? (order.client as User)._id : '',
          name: order ? order.name : '',
          price: order ? order.price : '',
          paid: order ? order.paid : false,
        }}
        onSubmit={async (values, actions) => {
          try {
            setErrorMessage('');
            if (order) {
              // Patch
              await patchOrder(order._id, values);
            } else {
              // Create
              await createOrder(values);
            }
            handleClose();
          } catch (error: any) {
            setErrorMessage(error.message);
          }
        }}
      >
        {({
          isSubmitting,
          errors,
          submitForm,
          dirty,
          touched,
          handleBlur,
          handleChange,
          setFieldValue,
          setTouched,
          resetForm,
        }) => (
          <>
            <DialogContent>
              <Form>
                <div style={{ paddingTop: '10px' }}>
                  <Field
                    component={FormSelect}
                    name="client"
                    label="Client"
                    options={clientOptions}
                  />
                </div>
                <Field
                  component={FormTextField}
                  name='name'
                  type='text'
                  label='Order name'
                  required
                />
                <Field
                  component={FormTextField}
                  name='price'
                  type='text'
                  label='Order Price'
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
                <Field
                  component={FormSwitch}
                  name='paid'
                  type='checkbox'
                  label='Paid'
                />
              </Form>
              <Box sx={{ width: '100%', paddingBottom: 1, textAlign: 'center' }}>
                <Typography variant='caption' color='red'>
                  {errorMessage}
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={submitForm}>Submit</Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default OrderForm;
