import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';

import { ParcelDetailed } from '../../../feathers/server-interface';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Form, Formik, Field } from 'formik';
import FormSelect from '../../../utility/forms/FormSelect';
import { patchParcel } from '../../../feathers/services/Parcel.service';
import { findShipments } from '../../../feathers/services/Shipment.service';

export interface AddToShipmentDialogInt {
  isOpen: boolean;
  parcel: ParcelDetailed | undefined;
}

export interface AddToShipmentDialogInterface {
  addToShipmentDialog: AddToShipmentDialogInt;
  setAddToShipmentDialog: React.Dispatch<React.SetStateAction<AddToShipmentDialogInt>>;
}

const AddToShipmentDialog: React.FC<AddToShipmentDialogInterface> = ({
  addToShipmentDialog,
  setAddToShipmentDialog,
}) => {
  const { isOpen, parcel } = addToShipmentDialog;
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [shipmentsOptions, setShipmentOptions] = useState<{ value: string; label: string; }[]>([]);
  const handleClose = () => {
    setAddToShipmentDialog({
      isOpen: false,
      parcel: undefined,
    });
  };

  useEffect(() => {
    const init = async () => {
      if (isOpen) {
        try {
          const foundShipments = await findShipments();
          setShipmentOptions(foundShipments.map((shipment) => ({ value: shipment._id, label: `Contract: ${shipment.contractNumber}` })))
        } catch (error: any) {
          setErrorMessage(error.message);
        }
      }
    };
    init();
  }, [isOpen]);

  const schema = Yup.object().shape({
    // weight: Yup.string().required('Weight is required'),
  });

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth>
      <DialogTitle>Add To Shipment</DialogTitle>
      <Formik
        enableReinitialize
        validationSchema={schema}
        initialValues={{
          shipment: '',
        }}
        onSubmit={async (values, actions) => {
          try {
            setErrorMessage('');
            if (parcel) {
              try {
                await patchParcel(parcel._id, { shipment: values.shipment });
              } catch (error: any) {
                setErrorMessage(error.message);
              }
            }
            handleClose();
          } catch (error: any) {
            setErrorMessage(error.message);
          }
        }}
      >
        {({
          isSubmitting,
          errors,
          submitForm,
          dirty,
          touched,
          handleBlur,
          handleChange,
          setFieldValue,
          setTouched,
          resetForm,
        }) => (
          <>
            <DialogContent>
              <Form>
                <div style={{ paddingTop: '10px' }}>
                  <Field
                    component={FormSelect}
                    name="shipment"
                    label="Add Parcel to Shipment"
                    options={shipmentsOptions}
                  />
                </div>
              </Form>
              <Box sx={{ width: '100%', paddingBottom: 1, textAlign: 'center' }}>
                <Typography variant='caption' color='red'>
                  {errorMessage}
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} variant='outlined'>Cancel</Button>
              <Button onClick={submitForm} variant='contained'>Submit</Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddToShipmentDialog;
