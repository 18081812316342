import React, { useState } from 'react';

import {
  Button, Box,
  Dialog, DialogTitle,DialogContent, DialogContentText, DialogActions,
} from '@mui/material';

const SampleDialog: React.FC = () => {
  const [simpleDialog, setSimpleDialog] = useState<boolean>(false);

  const handleClickOpen = () => {
    setSimpleDialog(true);
  };

  const handleClose = () => {
    setSimpleDialog(false);
  };
  return (
    <Box>
      <Button onClick={handleClickOpen}>Open Dialog</Button>
      <Dialog open={simpleDialog} onClose={handleClose} fullWidth>
        <DialogTitle>Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            kjhbjklm
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Subscribe</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SampleDialog;
