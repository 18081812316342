import { useEffect, useState } from 'react';
import { Paginated } from '@feathersjs/feathers';

import { findOrdersPaginated, orderFeathersService } from '../../../feathers/services/Order.service';
import { Order } from '../../../feathers/server-interface';

const useFetchOrdersPaginated = (
  searchQuery: string,
  selectedOrder: Order | undefined,
  setSelectedOrders: React.Dispatch<React.SetStateAction<Order | undefined>>,
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [orders, setOrders] = useState<Paginated<Order>>();

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      try {
        const foundOrders = await findOrdersPaginated(0, searchQuery);
        setOrders(foundOrders);
      } catch (error: any) {
        setError(error);
      }
      setIsLoading(false);
    };
    init();
  }, [searchQuery]);

  const loadMore = async () => {
    const currentTotal = orders?.total || 0;
    const currentSkip = orders?.skip || 0;
    const difference = currentTotal - currentSkip;
    const newSkipValue = difference < 10 ? currentSkip + difference :  currentSkip + 10;

    if (difference !== 0) {
      const fetchedOrders = await findOrdersPaginated(newSkipValue, searchQuery);
      if (!orders) setOrders(fetchedOrders);
      if (orders) {
        setOrders((prevState: any) => {
          return {
            ...prevState,
            data: [...prevState.data, ...fetchedOrders.data],
            skip: fetchedOrders.skip,
            total: fetchedOrders.total,
          }
        });
      }
    }
  };

  useEffect(() => {
    const handleOrderCreate = (createdOrder: Order) => {
      setOrders((prevState: any) => {
        return {
          ...prevState,
          data: [createdOrder, ...prevState.data],
          skip: prevState.skip + 1,
          total: prevState.total + 1,
        }
      });
    };

    const handleOrderPatch = (patchedOrder: Order) => {
      setOrders((prevState: any) => {
        return {
          ...prevState,
          data: prevState.data.map((order: Order) => {
            if (order._id === patchedOrder._id) return patchedOrder;
            return order;
          }),
        }
      });
      if (selectedOrder?._id === patchedOrder._id) {
        setSelectedOrders(patchedOrder);
      }
    };

    orderFeathersService.on('created', handleOrderCreate);
    orderFeathersService.on('patched', handleOrderPatch);

    return () => {
      orderFeathersService.removeListener('created', handleOrderCreate);
      orderFeathersService.removeListener('patched', handleOrderPatch);
    }
  }, [selectedOrder?._id, setSelectedOrders]);

  return {
    orders,
    isLoading,
    error,
    loadMore,
  };
};

export default useFetchOrdersPaginated;