import DashboardViewer from "../pages/dashboard/DashboardViewer";
import ClientViewer from "../pages/Client/ClientViewer";
import AppManagementViewer from "../pages/AppManagement/AppManagementViewer";
import ParcelsViewer from "../pages/Parcels/ParcelsViewer";
import ShipmentViewer from "../pages/Shipments/ShipmentViewer";
import OrderViewer from "../pages/Orders/OrderViewer";

import { Dashboard, People } from '@mui/icons-material';

export interface Module {
  id: string;
  name: string;
  route: string;
  icon: JSX.Element;
  component: JSX.Element;
  unmountOnClose: boolean;
  // isExpandable: boolean;
  // defaultExpand: boolean;
  // authorizedRoles: string[];
  padding?: number;
}

const modules: Module[] = [
  {
    id: 'dashboard',
    name: 'Dashboard',
    route: '/',
    icon: <Dashboard />,
    component: <DashboardViewer />,
    unmountOnClose: true,
    padding: 0,
  },
  {
    id: 'parcels',
    name: 'Parcels',
    route: '/parcels',
    icon: <People />,
    component: <ParcelsViewer />,
    unmountOnClose: true,
    padding: 0,
  },
  {
    id: 'client',
    name: 'Clients',
    route: '/client',
    icon: <People />,
    component: <ClientViewer />,
    unmountOnClose: true,
    padding: 0,
  },
  {
    id: 'shipments',
    name: 'Shipments',
    route: '/shipments',
    icon: <People />,
    component: <ShipmentViewer />,
    unmountOnClose: true,
    padding: 0,
  },
  {
    id: 'orders',
    name: 'Orders',
    route: '/orders',
    icon: <People />,
    component: <OrderViewer />,
    unmountOnClose: true,
    padding: 0,
  },
  {
    id: 'appmanagement',
    name: 'Manage App',
    route: '/appmanagement',
    icon: <People />,
    component: <AppManagementViewer />,
    unmountOnClose: true,
    padding: 0,
  },
];

export default modules;
