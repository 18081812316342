import React, { useContext, useState } from 'react';
import { Formik, Form, Field } from 'formik';

import FormSelect from '../utility/forms/FormSelect';
import ContextApp from '../../contexts/ContextApp';
import { AuthenticationStateAction } from '../../reducers/reducerAuthentication';
import FeathersApp, { Service } from '../feathers/Feathers';
import { Workspace, WpSwitch } from '../feathers/server-interface';

interface WorkspaceOptions {
  value: string;
  label: string;
}

const wpswitchFeatherService: any = FeathersApp.service(Service.WpSwitch);

const wpswitchPatch = (id: string, data: WpSwitch): Promise<WpSwitch> => (
  wpswitchFeatherService.patch(id, data)
);

const SelectWorkspace: React.FC = () => {
  const { authState, authDispatch } = useContext(ContextApp);
  const [error, setError] = useState<any>();
  const availableWorkspaces: WorkspaceOptions[] = authState.workspaces.map((workspace: Workspace) => {
    return { value: workspace._id || '', label: workspace.name || '' }
  });
  const defaultWorkspace: WorkspaceOptions =
    availableWorkspaces.find((workspace) => workspace.value === authState.defaultWorkspace) ||
    { value: '', label: '' };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        workspace: defaultWorkspace.value,
      }}
      onSubmit={async (values, actions) => {
        try {
          const { workspace: workspaceId } = values;
          const data = {
            value: workspaceId as unknown as string,
            label: availableWorkspaces.find((availWs: any) => availWs.value === workspaceId)?.label as string,
          };
          if (data.value && data.label) {
            const defaultWorkspace = await wpswitchPatch(authState._id, data);
            authDispatch({
              type: AuthenticationStateAction.UPDATE_USER_WS,
              payload: {
                defaultWorkspace: defaultWorkspace.value
              }
            });
          }
        } catch (error) {
          setError(error);
        }
        actions.setSubmitting(false);
      }}
    >
      {({
        submitForm,
        setFieldValue,
        handleChange
      }) => (
        <Form>
          <Field
            component={FormSelect}
            name="workspace"
            label="Select Workspace"
            options={availableWorkspaces}
            onChange={(event: any) => {
              handleChange(event);
              submitForm();
            }}
          />
          {/* <Field   
            component={FormSelect}
            name='workspace'
            options={availableWorkspaces}
            label='Select Workspace'
            // disableClearable
            onChange={(event: any) => {
              const selectedWorkspaceLabel = event.target.innerText;
              const selectedWorkspace = availableWorkspaces.find((
                workspace) => workspace.label === selectedWorkspaceLabel)
                  || { value: '', label: '' };
              setFieldValue('workspace', selectedWorkspace);
              submitForm();
            }}
          /> */}
          {
            error ? (
              <div style={{color: 'red'}}>
                {error.message}
              </div>
            ) : null
          }
        </Form>
      )}
    </Formik>
  );
};

export default SelectWorkspace;
