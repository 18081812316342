import { TextField, TextFieldProps } from 'formik-mui';

interface FormTextFieldInterface extends TextFieldProps {

}

const FormTextField = (props: FormTextFieldInterface) => {
  const { required, ...rest } = props;
  const label = required ? `${props.label} *` : props.label;
  return (
    <TextField
      {...rest}
      label={label}
      variant='standard'
      InputLabelProps={{
        shrink: true,
      }}
      sx={{ width: '100%', marginBottom: '10px' }}
    />
  );
};

export default FormTextField;
