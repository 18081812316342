import React, { useState } from 'react';

import LeftRightPanel from '../../layout/LeftRightPanel';
import ClientLeftPanel from './ClientLeftPanel';
import ClientRightPanel from './ClientRightPanel';
import { User } from '../../feathers/server-interface';

const Client: React.FC = () => {
  const [selectedClient, setSelectedClient] = useState<User | undefined>(undefined);

  return (
    <LeftRightPanel
      leftPanelComponent={(
        <ClientLeftPanel
          selectedClient={selectedClient}
          setSelectedClient={setSelectedClient}
        />
      )}
      rightPanelComponent={(
        <ClientRightPanel
          selectedClient={selectedClient}
          setSelectedClient={setSelectedClient}
        />
      )}
    />
  );
};

export default Client;
