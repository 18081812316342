import React, { useRef, useState } from 'react';
import {
  IconButton, Tooltip, Card, CardContent,
  Typography,
} from '@mui/material';
import { Cancel, Add } from '@mui/icons-material';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';

import PageTitle from '../../layout/PageTitle';
import { OrderFormInt } from './OrderForm';
import useDebounce from '../../helpers/hooks/useDebounce';
import SearchBar from '../../utility/SearchBar';
import useFetchOrdersPaginated from './hooks/useFetchOrdersPaginated';
import { Order, User } from '../../feathers/server-interface';

interface OrderLeftPanelInterface {
  selectedOrder: Order | undefined;
  setSelectedOrder: React.Dispatch<React.SetStateAction<Order | undefined>>;
  setOrderForm: React.Dispatch<React.SetStateAction<OrderFormInt>>; 
}

const OrderLeftPanel: React.FC<OrderLeftPanelInterface> = ({
  selectedOrder,
  setSelectedOrder,
  setOrderForm,
}) => {
  return (
    <>
      <PageTitle
        title='Orders'
        rightIcon={(
          <IconButton
            onClick={() => {
              setOrderForm({
                isOpen: true,
                order: undefined,
              });
            }}
          >
            <Tooltip title='Add New'>
              <Add />
            </Tooltip>
          </IconButton>
        )}
      />
      <OrderList
        selectedOrder={selectedOrder}
        setSelectedOrder={setSelectedOrder}
      />
    </>
  );
};

export default OrderLeftPanel;

interface OrderListInterface {
  selectedOrder: Order | undefined;
  setSelectedOrder: React.Dispatch<React.SetStateAction<Order | undefined>>;
}

const OrderList: React.FC<OrderListInterface> = ({
  selectedOrder,
  setSelectedOrder,
}) => {
  const virtuoso = useRef<VirtuosoHandle | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearchQuery = useDebounce(searchQuery, 250);
  const searchBarRef: any = useRef();

  const { orders, loadMore } = useFetchOrdersPaginated(searchQuery, selectedOrder, setSelectedOrder);

  return (
    <>
      <SearchBar
        defaultPlaceholder='Search Shipment'
        defaultValue={debouncedSearchQuery}
        onChange={(value: string) => setSearchQuery(value)}
        // setOnFocus={setDisplayList}
        ref={searchBarRef}
      />
      <div style={{ width: '100%', height: 'calc(100% - 64px)' }}>
        <Virtuoso
          ref={virtuoso}
          data={orders?.data || []}
          endReached={loadMore}
          totalCount={orders?.data.length || 0}
          itemContent={(
            index,
            order,
          ) => <OrderListItem
            order={order}
            selectedOrder={selectedOrder}
            setSelectedOrder={setSelectedOrder}
          />}
        />
      </div>
    </>
  );
};

interface OrderListItemInterface {
  order: Order;
  selectedOrder: Order | undefined;
  setSelectedOrder: React.Dispatch<React.SetStateAction<Order | undefined>>;
}

const OrderListItem: React.FC<OrderListItemInterface> = ({
  order,
  selectedOrder,
  setSelectedOrder,
}) => {

  return (
    <Card
      sx={{
        // width: '100%',
        height: '100px',
        marginBottom: '10px',
        cursor: 'pointer',
        borderColor: order._id === selectedOrder?._id ? '#1976d2' : '',
      }}
      variant='outlined'
      onClick={() => setSelectedOrder(order)}
    >
      <CardContent>
        <Typography variant='h6'>{order.name}</Typography>
        <Typography variant='caption'>{(order.client as User).username}</Typography>
      </CardContent>
    </Card>
  );
};
