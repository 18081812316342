import React from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import { Button } from '@mui/material';
import Samples from './Samples';
import SampleForm from './components/SampleForm';
import SampleTypography from './components/SampleTypography';
import SampleDialog from './components/SampleDialog';

const SampleViewer =  () => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Link to='form'>
        <Button>Form</Button>
      </Link>
      <Link to='typography'>
        <Button>Typography</Button>
      </Link>
      <Link to='dialog'>
        <Button>Dialog</Button>
      </Link>
      <Routes>
        <Route
          path='form'
          element={<SampleForm />}
        />
        <Route
          path='typography'
          element={<SampleTypography />}
        />
        <Route
          path='dialog'
          element={<SampleDialog />}
        />
      </Routes>
    </div>
  );
};

export default SampleViewer;
