import React, { useEffect, useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, Box, Typography,
} from '@mui/material';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';

import FormSelect from '../../../utility/forms/FormSelect';
import { patchParcel } from '../../../feathers/services/Parcel.service';
import { findOrders } from '../../../feathers/services/Order.service';
import { ParcelDetailed } from '../../../feathers/server-interface';

export interface AddToOrderDialogInt {
  isOpen: boolean;
  parcel: ParcelDetailed | undefined;
}

export interface AddToOrderDialogInterface {
  addToOrderDialog: AddToOrderDialogInt;
  setAddToOrderDialog: React.Dispatch<React.SetStateAction<AddToOrderDialogInt>>;
}

const AddToOrderDialog: React.FC<AddToOrderDialogInterface> = ({
  addToOrderDialog,
  setAddToOrderDialog,
}) => {
  const { isOpen, parcel } = addToOrderDialog;
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [orderOptions, setOrderOptions] = useState<{ value: string; label: string; }[]>([]);
  const handleClose = () => {
    setAddToOrderDialog({
      isOpen: false,
      parcel: undefined,
    });
  };

  useEffect(() => {
    const init = async () => {
      if (isOpen) {
        try {
          const foundOrders = await findOrders();
          setOrderOptions(foundOrders.map((order) => ({ value: order._id, label: order.name })))
        } catch (error: any) {
          setErrorMessage(error.message);
        }
      }
    };
    init();
  }, [isOpen]);

  const schema = Yup.object().shape({
    // weight: Yup.string().required('Weight is required'),
  });

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth>
      <DialogTitle>Add To Order</DialogTitle>
      <Formik
        enableReinitialize
        validationSchema={schema}
        initialValues={{
          order: '',
        }}
        onSubmit={async (values, actions) => {
          try {
            setErrorMessage('');
            if (parcel) {
              try {
                await patchParcel(parcel._id, { order: values.order });
              } catch (error: any) {
                setErrorMessage(error.message);
              }
            }
            handleClose();
          } catch (error: any) {
            setErrorMessage(error.message);
          }
        }}
      >
        {({
          isSubmitting,
          errors,
          submitForm,
          dirty,
          touched,
          handleBlur,
          handleChange,
          setFieldValue,
          setTouched,
          resetForm,
        }) => (
          <>
            <DialogContent>
              <Form>
                <div style={{ paddingTop: '10px' }}>
                  <Field
                    component={FormSelect}
                    name="order"
                    label="Add Parcel to Order"
                    options={orderOptions}
                  />
                </div>
              </Form>
              <Box sx={{ width: '100%', paddingBottom: 1, textAlign: 'center' }}>
                <Typography variant='caption' color='red'>
                  {errorMessage}
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} variant='outlined'>Cancel</Button>
              <Button onClick={submitForm} variant='contained'>Submit</Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddToOrderDialog;
