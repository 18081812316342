import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './Dashboard';
import DashboardParcelForm from './DashboardParcelForm';

const DashboardViewer: React.FC = (): any => (
  <div style={{ width: '100%', height: '100%' }}>
    <Routes>
      <Route
        path='/'
        element={<Dashboard />}
      />
      <Route
        path='CreateParcel'
        element={<DashboardParcelForm />}
      />
      <Route
        path='editparcel/:id'
        element={<DashboardParcelForm />}
      />
    </Routes>
  </div>
);

export default DashboardViewer;
