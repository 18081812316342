import { useEffect, useState } from 'react';
import { Paginated } from '@feathersjs/feathers';

import { findShipmentsPaginated, shipmentFeathersService } from '../../../feathers/services/Shipment.service';
import { Shipment } from '../../../feathers/server-interface';

const useFetchShipmentsPaginated = (
  searchQuery: string,
  selectedShipment: Shipment | undefined,
  setSelectedShipments: React.Dispatch<React.SetStateAction<Shipment | undefined>>,
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [shipments, setShipments] = useState<Paginated<Shipment>>();

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      try {
        const foundShipments = await findShipmentsPaginated(0, searchQuery);
        setShipments(foundShipments);
      } catch (error: any) {
        setError(error);
      }
      setIsLoading(false);
    };
    init();
  }, [searchQuery]);

  const loadMore = async () => {
    const currentTotal = shipments?.total || 0;
    const currentSkip = shipments?.skip || 0;
    const difference = currentTotal - currentSkip;
    const newSkipValue = difference < 10 ? currentSkip + difference :  currentSkip + 10;

    if (difference !== 0) {
      const fetchedShipments = await findShipmentsPaginated(newSkipValue, searchQuery);
      if (!shipments) setShipments(fetchedShipments);
      if (shipments) {
        setShipments((prevState: any) => {
          return {
            ...prevState,
            data: [...prevState.data, ...fetchedShipments.data],
            skip: fetchedShipments.skip,
            total: fetchedShipments.total,
          }
        });
      }
    }
  };

  useEffect(() => {
    const handleShipmentCreate = (createdShipment: Shipment) => {
      setShipments((prevState: any) => {
        return {
          ...prevState,
          data: [createdShipment, ...prevState.data],
          skip: prevState.skip + 1,
          total: prevState.total + 1,
        }
      });
    };

    const handleShipmentPatch = (patchedShipment: Shipment) => {
      setShipments((prevState: any) => {
        return {
          ...prevState,
          data: prevState.data.map((shipment: Shipment) => {
            if (shipment._id === patchedShipment._id) return patchedShipment;
            return shipment;
          }),
        }
      });
      if (selectedShipment?._id === patchedShipment._id) {
        setSelectedShipments(patchedShipment);
      }
    };

    shipmentFeathersService.on('created', handleShipmentCreate);
    shipmentFeathersService.on('patched', handleShipmentPatch);

    return () => {
      shipmentFeathersService.removeListener('created', handleShipmentCreate);
      shipmentFeathersService.removeListener('patched', handleShipmentPatch);
    }
  }, [selectedShipment?._id, setSelectedShipments]);

  return {
    shipments,
    isLoading,
    error,
    loadMore,
  };
};

export default useFetchShipmentsPaginated;
