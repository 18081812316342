import React, { useState, useEffect } from 'react';
import {
  Box, Paper, Typography, Button,
  Grid, IconButton, Tooltip,
  TableContainer, Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import { Edit, CheckCircle, Cancel } from '@mui/icons-material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import FormTextField from '../../../utility/forms/FormTextField';
import FormSelectMulti from '../../../utility/forms/FormSelectMulti';
import FormSwitch from '../../../utility/forms/FormSwitch';
import useFetchWorkspaces from './hooks/useFetchWorkspaces';
import { workspaceCreate, workspacePatch } from '../../../feathers/services/Workspace.service';
import { usersFindAcWs } from '../../../feathers/services/User.service';
import { Workspace } from '../../../feathers/server-interface';

const ManageWorkspaces: React.FC = () => {
  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace | undefined>(undefined);
  const { workspaces } = useFetchWorkspaces();
  const [workspaceUsersOptions, setAllWorkspaceUsersOptions] = useState<{value: string; label: string;}[]>([]);

  useEffect(() => {
    const init = async () => {
      try {
        const foundWsUsers = await usersFindAcWs();
        setAllWorkspaceUsersOptions(foundWsUsers.map((user) => ({ label: user.username, value: user._id })));
      } catch (error) {
        
      }
    };
    init();
  }, []);

  const schema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });
  console.log(workspaces);
  return (
    <div>
      <Box
        component={Paper}
        variant='outlined'
        sx={{ padding: '10px', marginBottom: '10px' }}
      >
        <Typography>{selectedWorkspace ? 'Edit' : 'Create'} Workspace</Typography>
        <Formik
          enableReinitialize
          validationSchema={schema}
          initialValues={{
            _id: selectedWorkspace ? selectedWorkspace._id : '',
            name: selectedWorkspace ? selectedWorkspace.name : '',
            address: selectedWorkspace && selectedWorkspace.address ? selectedWorkspace.address : '',
            workspaceAdmins: selectedWorkspace ? selectedWorkspace.workspaceAdmins.map((admin: any) => ({ value: admin._id, label: admin.username })) : [],
            workspaceMembers: selectedWorkspace ? selectedWorkspace.workspaceMembers.map((member: any) => ({ value: member._id, label: member.username })) : [],
            destinationWarehouse: selectedWorkspace ? selectedWorkspace.destinationWarehouse : false,
            forwardingWarehouse: selectedWorkspace ? selectedWorkspace.forwardingWarehouse : false,
            // workspaceAdmins: selectedWorkspace ? selectedWorkspace.workspaceAdmins : [],
            // workspaceMembers: selectedWorkspace ? selectedWorkspace.workspaceMembers : [],
          }}
          onSubmit={async (values, actions) => {
            console.log('values: ', values);
            actions.setSubmitting(true);
            try {
              if (selectedWorkspace) {
                // Patch
                const patchData = {
                  ...values,
                  workspaceAdmins: values.workspaceAdmins.map((admin: { value: string; label: string; }) => admin.value),
                  workspaceMembers: values.workspaceMembers.map((member: { value: string; label: string; }) => member.value),
                };
                await workspacePatch(patchData._id, patchData);
              } else {
                // Create
                const { _id, ...rest } = values;
                const createData = {
                  ...rest,
                  workspaceAdmins: values.workspaceAdmins.map((admin: { value: string; label: string; }) => admin.value),
                  workspaceMembers: values.workspaceMembers.map((member: { value: string; label: string; }) => member.value),
                };
                await workspaceCreate(createData);
              }
              actions.resetForm();
            } catch (error) {
              console.log('error: ', error);
            }
            actions.setSubmitting(false);
          }}
        >
          {({
            isSubmitting,
            errors,
            submitForm,
            dirty,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
            setTouched,
            resetForm,
          }) => (
            <Form>
              <Field
                component={FormTextField}
                name='name'
                type='text'
                label='Workspace Name'
                required
              />
              <Field
                component={FormTextField}
                name='adress'
                type='text'
                label='Address'
                required
              />
              <Grid container>
                <Grid item xs={6} md={6}>
                  <Field
                    component={FormSwitch}
                    name='forwardingWarehouse'
                    type='checkbox'
                    label='Fowarding Warehouse'
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <Field
                    component={FormSwitch}
                    name='destinationWarehouse'
                    type='checkbox'
                    label='Destination Warehouse'
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xl={6} md={6} xs={12} sx={{ padding: '3px' }}>
                  <Field
                    component={FormSelectMulti}
                    name="workspaceAdmins"
                    label="Workspace Admins"
                    options={workspaceUsersOptions}
                  />
                </Grid>
                <Grid item xl={6} md={6} xs={12} sx={{ padding: '3px' }}>
                  <Field
                    component={FormSelectMulti}
                    name="workspaceMembers"
                    label="Workspace Members"
                    options={workspaceUsersOptions}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xl={6} md={6} xs={12} sx={{ padding: '3px' }}>
                  <Button
                    variant='contained'
                    sx={{ width: '100%', }}
                    type='submit'
                    disabled={isSubmitting || !dirty || (Object.keys(errors).length > 0)}
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item xl={6} md={6} xs={12} sx={{ padding: '3px' }}>
                  <Button
                    variant='outlined'
                    sx={{ width: '100%', }}
                    type='button'
                    onClick={() => {
                      setSelectedWorkspace(undefined);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>

      <TableContainer variant='outlined' component={Paper}>
        <Table sx={{ }} size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '2%' }}></TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Users</TableCell>
              <TableCell>Admins</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              workspaces.map((workspace) => (
                <TableRow key={workspace._id}>
                  <TableCell sx={{ width: '2%' }}>
                    {
                      workspace.forwardingWarehouse ? (
                        <CheckCircle fontSize='small' sx={{ color: 'green' }} />
                        ) : (
                        <Cancel fontSize='small' sx={{ color: 'red' }} />
                      )
                    }
                    {
                      workspace.destinationWarehouse ? (
                        <CheckCircle fontSize='small' sx={{ color: 'green' }} />
                        ) : (
                        <Cancel fontSize='small' sx={{ color: 'red' }} />
                      )
                    }
                  </TableCell>
                  <TableCell>{workspace.name}</TableCell>
                  <TableCell>{workspace.address || ''}</TableCell>
                  <TableCell>
                    {workspace.workspaceMembers.map((member: any) => member.username).join(', ')}
                  </TableCell>
                  <TableCell>
                    {workspace.workspaceAdmins.map((member: any) => member.username).join(', ')}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => {
                        setSelectedWorkspace(workspace);
                      }}
                    >
                      <Tooltip title='Edit'>
                        <Edit fontSize='small'/>
                      </Tooltip>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ManageWorkspaces;
