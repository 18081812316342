import { useEffect, useState } from "react";
import { Paginated } from '@feathersjs/feathers';

import { findParcelsByWarehousePaginated, parcelFeathersService } from '../../../feathers/services/Parcel.service';
import { ParcelDetailed, Workspace } from '../../../feathers/server-interface';

const useFetchParcelsByWorkspace = (
  warehouseId: string,
  searchQuery: string,
  selectedParcel: ParcelDetailed | undefined,
  setSelectedParcel: React.Dispatch<React.SetStateAction<ParcelDetailed | undefined>>,
) => {
  const [parcels, setParcels] = useState<Paginated<ParcelDetailed>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      try {
        const fetchedWarehouseParcels = await findParcelsByWarehousePaginated(warehouseId, 0, searchQuery);
        setParcels(fetchedWarehouseParcels); 
      } catch (error: any) {
        setError(error.message);
      }
      setIsLoading(false);
    }
    init();
  }, [searchQuery, warehouseId]);

  const loadMore = async () => {
    const currentTotal = parcels?.total || 0;
    const currentSkip = parcels?.skip || 0;
    const difference = currentTotal - currentSkip;
    const newSkipValue = difference < 10 ? currentSkip + difference :  currentSkip + 10;

    if (difference !== 0) {
      const fetchedWarehouseParcels = await findParcelsByWarehousePaginated(warehouseId, newSkipValue, searchQuery);
      if (!parcels) setParcels(fetchedWarehouseParcels);
      if (parcels) {
        setParcels((prevState: any) => {
          return {
            ...prevState,
            data: [...prevState.data, ...fetchedWarehouseParcels.data],
            skip: fetchedWarehouseParcels.skip,
            total: fetchedWarehouseParcels.total,
          }
        });
      }
    }
  }

  useEffect(() => {
    const handleParcelCreate = (createdParcel: ParcelDetailed) => {
      if (
        (createdParcel.forwardingWarehouse as Workspace)._id === warehouseId
        || (createdParcel.destinationWarehouse as Workspace)._id === warehouseId
      ) {
        setParcels((prevState: any) => {
          return {
            ...prevState,
            data: [createdParcel, ...prevState.data],
            skip: prevState.skip + 1,
            total: prevState.total + 1,
          }
        });
      }
    };

    const handleParcelPatch = (patchedParcel: ParcelDetailed) => {
      if (
        (patchedParcel.forwardingWarehouse as Workspace)._id === warehouseId
        || (patchedParcel.destinationWarehouse as Workspace)._id === warehouseId
      ) {
        setParcels((prevState: any) => {
          return {
            ...prevState,
            data: prevState.data.map((parcel: ParcelDetailed) => {
              if (parcel._id === patchedParcel._id) return patchedParcel;
              return parcel;
            }),
          }
        });
        if (selectedParcel?._id === patchedParcel._id) setSelectedParcel(patchedParcel);
      }
    };

    parcelFeathersService.on('created', handleParcelCreate);
    parcelFeathersService.on('patched', handleParcelPatch);

    return () => {
      parcelFeathersService.removeListener('created', handleParcelCreate);
      parcelFeathersService.removeListener('patched', handleParcelPatch);
    }
  }, [selectedParcel?._id, setSelectedParcel, warehouseId]);

  return {
    parcels,
    isLoading,
    error,
    loadMore,
  };
};

export default useFetchParcelsByWorkspace;
