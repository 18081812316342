import React, { Fragment, useEffect, useState } from 'react';
import { ParcelDetailed, Shipment, Upload, User, Workspace } from '../../feathers/server-interface';
import { Cancel } from '@mui/icons-material';
import { Box, Button, Chip, Collapse, Grid, IconButton, Paper, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Tooltip, Typography } from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown, Edit } from '@mui/icons-material';

import { ParcelArticlesMeasurementsTable, ParcelArticlesMeasurementTableInterface, MeasurementsDialog, MeasurementDialogInt } from '../Parcels/ParcelsRightPanel';
import useFetchClientParcels from './hooks/useFetchClientParcels';
import PageTitle from '../../layout/PageTitle';
import { useNavigate } from 'react-router-dom';
import { DateTimeSingleLine } from '../../utility/DateTimeHelpers';
import { findParcelsByModule, getUploadById } from '../../feathers/services/Upload.service';
import { toBlob } from '../../utility/forms/UploadFileField';
import AddToShipmentDialog, { AddToShipmentDialogInt } from '../Shipments/components/AddToShipmentDialog';
import AddToOrderDialog, { AddToOrderDialogInt } from '../Orders/components/AddToOrderDialog';

interface ClientRightPanelInterface {
  selectedClient: User | undefined;
  setSelectedClient: React.Dispatch<React.SetStateAction<User | undefined>>;
}

const ClientRightPanel: React.FC<ClientRightPanelInterface> = ({
  selectedClient,
  setSelectedClient,
}) => {
  if (!selectedClient) return null;
  return (
    <div style={{ paddingTop: '10px', paddingRight: '10px', paddingLeft: '10px' }}>
      <PageTitle
        title={selectedClient.username}
        rightIcon={(
          <>
            <Button size='small' variant='outlined' sx={{ marginLeft: '10px' }}>Create Order</Button>
            <Button size='small' variant='outlined' sx={{ marginLeft: '10px' }}>Create Parcel</Button>
            <IconButton onClick={() => setSelectedClient(undefined)}>
              <Tooltip title='Close'>
                <Cancel />
              </Tooltip>
            </IconButton>
          </>
        )}
      />
      <Box component={Paper} variant='outlined' p={1} marginBottom={1}>
        <Typography>Cllient information here</Typography>
      </Box>
      <ClientParcels clientId={selectedClient._id} />
    </div>
  );
};

export default ClientRightPanel;

interface ClientParcelsInterface {
  clientId: string;
}

const ClientParcels: React.FC<ClientParcelsInterface> = ({
  clientId,
}) => {
  const { clientParcels } = useFetchClientParcels(clientId);

  return (
    // <Box component={Paper} variant='outlined' p={1}>
    <div style={{  paddingRight: '10px'}}>
      <Stack direction='row' justifyItems='center' justifyContent='space-between' alignItems='center'>
        <Typography variant='body1' fontWeight='bold'>Parcels</Typography>
        {/* <Button size='small' variant='contained'>+ Create Parcel for client</Button> */}
      </Stack>
      <TableContainer component={Paper} variant='outlined' sx={{ margin: '5px 0 20px 0' }}>
        <Table aria-label="collapsible table" size='small'>
          <TableHead>
            <TableRow>
              <TableCell width={'3%'} />
              <TableCell width={'23%'}>
                <Typography variant='caption' fontWeight='bold'>JNC Ref</Typography>
              </TableCell>
              <TableCell width={'23%'}>
                <Typography variant='caption' fontWeight='bold'>Tracking number</Typography>
              </TableCell>
              <TableCell width={'15%'}>
                <Typography variant='caption' fontWeight='bold'>From</Typography>
              </TableCell>
              <TableCell width={'15%'}>
                <Typography variant='caption' fontWeight='bold'>To</Typography>
              </TableCell>
              <TableCell width={'15%'} align='center'>
                <Typography variant='caption' fontWeight='bold'>Shipment</Typography>
              </TableCell>
              <TableCell width={'15%'} align='center'>
                <Typography variant='caption' fontWeight='bold'>Order</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              (clientParcels || []).map((clientParcel: ParcelDetailed, key: number) => (
                <Fragment key={`parcel-tab-${key}`}>
                  <ClientParcelRow parcel={clientParcel} />
                </Fragment>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
    // </Box>
  );
};

const ClientParcelRow: React.FC<{ parcel: ParcelDetailed }> = ({
  parcel,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<number>(0);
  const [measurementDialog, setMeasurementDialog] = useState<MeasurementDialogInt>({
    isOpen: false,
    parcelId: '',
    measurement: undefined,
  });
  const [addToShipmentDialog, setAddToShipmentDialog] = useState<AddToShipmentDialogInt>({
    isOpen: false,
    parcel: undefined,
  });
  const [addToOrderDialog, setAddToOrderDialog] = useState<AddToOrderDialogInt>({
    isOpen: false,
    parcel: undefined,
  });
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, ...(open ? {backgroundColor: '#EDEDED'} : {}) }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography variant='body2' fontWeight='bold' color='secondary'>JNC2023913832</Typography>
          {DateTimeSingleLine(parcel.createInfo?.dateTime as Date)}
        </TableCell>
        <TableCell>
          <Typography variant='body2' fontWeight='bold'>{parcel.trackingNumber}</Typography>
          <Typography variant='caption'>{parcel.courierCompany}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body2' fontWeight='bold'>{(parcel.forwardingWarehouse as Workspace).name}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body2' fontWeight='bold'>{(parcel.destinationWarehouse as Workspace).name}</Typography>
        </TableCell>
        <TableCell align='center'>
          <Typography variant='caption'>
            {
              !parcel.shipment ? (
                <Button
                  size='small'
                  onClick={() => {
                    setAddToShipmentDialog({
                      isOpen: true,
                      parcel: parcel,
                    });
                  }}
                >
                  Add
                </Button>
              ) : (
                <Typography
                  variant='body2'
                  color='blue'
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(`/shipments`, { state: { shipment: (parcel.shipment as Shipment)._id } });
                  }}
                >
                  {(parcel.shipment as Shipment).contractNumber}
                </Typography>
              )
            }
            <AddToShipmentDialog
              addToShipmentDialog={addToShipmentDialog}
              setAddToShipmentDialog={setAddToShipmentDialog}
            />
            <AddToOrderDialog
              addToOrderDialog={addToOrderDialog}
              setAddToOrderDialog={setAddToOrderDialog}
            />
          </Typography>
        </TableCell>
        <TableCell align='center'>
          <Typography variant='caption'>
            {
              !parcel.order ? (
                <Button
                  size='small'
                  onClick={() => {
                    // setAddToShipmentDialog({
                    //   isOpen: true,
                    //   parcel: parcel,
                    // });
                  }}
                >
                  Add
                </Button>
              ) : (
                <Typography
                  variant='body2'
                  color='blue'
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    // navigate(`/shipments`, { state: { shipment: (parcel.shipment as Shipment)._id } });
                  }}
                >
                  {(parcel.shipment as Shipment).contractNumber}
                </Typography>
              )
            }
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7} sx={{ ...(open ? {backgroundColor: '#EDEDED'} : {}) }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ width: '100%', bgcolor: 'background.paper', marginBottom: 1 }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example" textColor='secondary' indicatorColor='secondary'>
                  <Tab label="Detail" {...a11yProps(0)} />
                  <Tab label="Documents" {...a11yProps(1)} />
                  <Tab label="Article/Measurements" {...a11yProps(2)} />
                </Tabs>
                <TabPanel value={tabValue} index={0}>
                  <Grid container>
                    <Grid item xs={3}>
                      <Typography variant='caption'>Parcel Value:</Typography>
                      <Typography variant='body2'>$ {parcel.parcelValue}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant='caption'>Sensitive Cargo:</Typography>
                      <Typography variant='body2' color={parcel.sensitiveCargo ? 'red' : 'primary'}>{parcel.sensitiveCargo ? 'Yes' : 'No'}</Typography>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <DisplayUploadedDocuments
                    parcelId={parcel._id}
                  />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                  <ParcelArticlesMeasurementsTable
                    parcelId={parcel._id}
                    measurements={parcel.articleMeasurements || []}
                    measurementDialog={measurementDialog}
                    setMeasurementDialog={setMeasurementDialog}
                  />
                  <Button
                    variant='outlined'
                    onClick={() => {
                      setMeasurementDialog({
                        isOpen: true,
                        parcelId: parcel._id,
                        measurement: undefined,
                      });
                    }}
                  >
                    Add Box
                  </Button>
                  <MeasurementsDialog
                    measurementDialog={measurementDialog}
                    setMeasurementDialog={setMeasurementDialog}
                  />
                </TabPanel>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

interface DisplayUploadedDocumentsInterface {
  parcelId: string;
}

const DisplayUploadedDocuments: React.FC<DisplayUploadedDocumentsInterface> = ({
  parcelId,
}) => {
  const [emptyUploads, setEmptyUploads] = useState<Upload[]>([]);
  useEffect(() => {
    const getEmptyUploads = async () => {
      try {
        const foundEmptyUploads = await findParcelsByModule('parcels', parcelId, true);
        setEmptyUploads(foundEmptyUploads);
      } catch (error) {
        console.log('error: ', error);
      }
    };
    getEmptyUploads();
  }, [parcelId]);

  return (
    <Box>
      <Stack direction="row" spacing={1}>
        {
          emptyUploads.map((file) => (
            <Chip
              key={file._id}
              label={file.fileName}
              onClick={async () => {
                const foundUpload = await getUploadById(file._id);
                const blob = await toBlob(foundUpload.uri, foundUpload.fileName, foundUpload.fileType);
                var csvURL = window.URL.createObjectURL(blob);
                var tempLink = document.createElement('a');
                tempLink.href = csvURL;
                tempLink.setAttribute('download', foundUpload.fileName);
                tempLink.click();
              }}
            />
          ))
        }
      </Stack>
    </Box>
  );
};