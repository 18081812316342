import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button, Box, Paper, Grid,
  Stack, Typography,
  TableContainer, TableBody,
  Table, TableHead, TableRow, TableCell,
} from '@mui/material';
import * as XLSX from 'xlsx';
// import path from 'path';

import { OrderFormInt } from './OrderForm';
import PageTitle from '../../layout/PageTitle';
import useFetchParcelsByOrder from './hooks/useFetchParcelsByOrder';
import { Order, User, ParcelDetailed, ParcelArticleMeasurement } from '../../feathers/server-interface';

interface OrderRightPanelInterface {
  selectedOrder: Order | undefined;
  setSelectedOrder: React.Dispatch<React.SetStateAction<Order | undefined>>;
  setOrderForm: React.Dispatch<React.SetStateAction<OrderFormInt>>;
}

const OrderRightPanel: React.FC<OrderRightPanelInterface> = ({
  selectedOrder,
  setSelectedOrder,
  setOrderForm,
}) => {

  if (!selectedOrder) return null;
  return (
    <div style={{ paddingTop: '10px', paddingRight: '10px', paddingLeft: '10px' }}>
      <PageTitle
        title={`${selectedOrder?.name}`}
        rightIcon={(
          <Button
            size='small'
            variant='outlined'
            onClick={() => {
              setOrderForm({
                isOpen: true,
                order: selectedOrder,
              });
            }}
          >
            Edit
          </Button>
        )}
      />
      <Box component={Paper} variant='outlined' marginTop={1}>
        <Grid container>
          <Grid item xs={6} md={3}>
            <Box p={1}>
              <Typography variant='caption' display='block' fontWeight='bold'>Client</Typography>
              <Typography variant='body2'>{(selectedOrder?.client as User).username}</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box p={1}>
              <Typography variant='caption' display='block' fontWeight='bold'>Price</Typography>
              <Typography variant='body2'>{selectedOrder?.price ? `$${selectedOrder.price}` : '-'}</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box p={1}>
              <Typography variant='caption' display='block' fontWeight='bold'>Paid</Typography>
              <Typography variant='body2'>{selectedOrder?.paid ? 'Yes' : 'No'}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ParcelList
        order={selectedOrder}
      />
    </div>
  );
};

export default OrderRightPanel;

interface ParcelListInterface {
  order: Order;
}

const ParcelList: React.FC<ParcelListInterface> = ({
  order,
}) => {
  const {parcels} = useFetchParcelsByOrder(order._id);
  return (
    <>
      <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ marginBottom: 1, marginTop: 1 }}>
        <Typography variant='body1' fontWeight='bold'>Parcels</Typography>
        <Button
          variant='outlined'
          size='small'
          onClick={() => {
            const worksheetName = `Order ${order.name}`;
            const colNames = [
              'Parcels',
              'courierCompany',
              'Cartons',
              'SensitiveCargo',
            ];
            const filePath = `${worksheetName}.xlsx`;
            const transformedData = parcels.map((parcel) => [
              parcel.trackingNumber,
              parcel.courierCompany,
              parcel.articleMeasurements?.length || '-',
              parcel.sensitiveCargo ? 'Yes' : 'No',
            ]);
            const worksheetData = [colNames, ...transformedData];
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
            XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName);
            XLSX.writeFile(workbook, filePath);
            // XLSX.writeFile(workbook, path.resolve(filePath));
          }}
        >
          Export Order Parcels
        </Button>
      </Stack>
      <TableContainer component={Paper} variant='outlined'>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell width={'30%'}>
                <Typography variant='caption' fontWeight='bold'>Tracking number</Typography>
              </TableCell>
              <TableCell width={'25%'} align='center'>
                <Typography variant='caption' fontWeight='bold'>No. of boxes</Typography>
              </TableCell>
              <TableCell width={'35%'} align='center'>
                <Typography variant='caption' fontWeight='bold'>Measurements</Typography>
              </TableCell>
              <TableCell width={'10%'} align='right'>
                <Typography variant='caption' fontWeight='bold'>Sensitive</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              parcels.map((parcel: ParcelDetailed, key: number) => (
                <Fragment key={`displayParcel-${key}-${parcel._id}`}>
                  <ParcelListRow parcel={parcel} />
                </Fragment>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

interface ParcelListRowInterface {
  parcel: ParcelDetailed;
}

const getArticleVolume = (article: ParcelArticleMeasurement) => {
  const { width, length, height } = article;
  return Number(width) * Number(length) * Number(height)
};

const ParcelListRow: React.FC<ParcelListRowInterface> = ({
  parcel,
}) => {
  const navigate = useNavigate();

  return (
    <TableRow>
      <TableCell>
        <Typography
          variant='body2'
          fontWeight='bold'
          color='secondary'
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            navigate(`/parcels`, { state: { parcel: parcel._id } });
          }}
        >
          {parcel.trackingNumber}
        </Typography>
        <Typography variant='caption' fontWeight='bold'>{parcel.courierCompany}</Typography>
      </TableCell>
      <TableCell align='center'>
        <Typography variant='body2'>{parcel.articleMeasurements?.length || '-'}</Typography>
      </TableCell>
      <TableCell align='center'>
        {
          parcel.articleMeasurements?.map((articleMeasurement: ParcelArticleMeasurement) => (
            <Typography
              variant='caption'
              display='block'
            >
              {articleMeasurement.weight}kg, {articleMeasurement.length}cm x {articleMeasurement.width}cm x {articleMeasurement.height}cm  
            </Typography>
          ))
        }
      </TableCell>
      <TableCell align='right'>
        <Typography variant='body2' color={parcel.sensitiveCargo ? 'red' : ''}>{parcel.sensitiveCargo ? 'Yes' : 'No'}</Typography>
      </TableCell>
    </TableRow>
  );
};