import React from 'react';
import { TextField } from '@mui/material';
import { Autocomplete, AutocompleteRenderInputParams, AutocompleteProps } from 'formik-mui';

interface FormSelectMultipleInterface extends AutocompleteProps<string, true, true, true> {
  // onChange?: any;
  required?: boolean;
  label: string,
}

const FormSelectMulti = (props: FormSelectMultipleInterface) => {
  const { required, ...rest } = props;
  const label = required ? `${props.label} *` : props.label;

  return (
    <Autocomplete
      {...rest}
      getOptionLabel={(option: any) => option.label}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          error={props.form.touched[rest.field.name] && !!props.form.errors[rest.field.name]}
          label={label}
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      isOptionEqualToValue={(option: any, value: any) => {return option.value === value.value}}
      multiple
      filterSelectedOptions
      sx={{  marginBottom: '10px' }}
    />
  );
};

export default FormSelectMulti;
