import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import LeftRightPanel from '../../layout/LeftRightPanel';
import ShipmentLeftPanel from './ShipmentLeftPanel';
import ShipmentRightPanel from './ShipmentRightPanel';
import ShipmentForm, { ShipmentFormInt } from './ShipmentForm';
import { getShipment } from '../../feathers/services/Shipment.service';
import { Shipment as ShipmentInt } from '../../feathers/server-interface';

const Shipment: React.FC = () => {
  const { state } = useLocation();

  const [selectedShipment, setSelectedShipment] = useState<ShipmentInt | undefined>(undefined);
  const [shipmentForm, setShipmentForm] = useState<ShipmentFormInt>({
    isOpen: false,
    shipment: undefined,
  });

  useEffect(() => {
    if (state) {
      const shipmentId = state.shipment as unknown as string;
      const getSelectedShipment = async () => {
        const foundShipment = await getShipment(shipmentId);
        setSelectedShipment(foundShipment);
      }
      getSelectedShipment();
    }
  }, [state]);

  return (
    <>
      <LeftRightPanel
        leftPanelComponent={(
          <ShipmentLeftPanel
            selectedShipment={selectedShipment}
            setSelectedShipment={setSelectedShipment}
            setShipmentForm={setShipmentForm}
          />
        )}
        rightPanelComponent={(
          <ShipmentRightPanel
            selectedShipment={selectedShipment}
            setSelectedShipment={setSelectedShipment}
            setShipmentForm={setShipmentForm}
          />
        )}
      />
      <ShipmentForm
        shipmentForm={shipmentForm}
        setShipmentForm={setShipmentForm}
      />
    </>
  );
};

export default Shipment;
