import React, { Fragment } from 'react';
import {
  Grid, Typography, Box, Paper,
  Button, Stack,
  TableContainer, Table, TableHead, TableRow, TableCell,
  TableBody,
} from '@mui/material';
import * as XLSX from 'xlsx';
// import path from 'path';

import { ShipmentFormInt } from './ShipmentForm';
import useFetchParcelsByShipment from './hooks/useFetchParcelsByShipment';
import { Order, ParcelDetailed, Shipment, User } from '../../feathers/server-interface';
import PageTitle from '../../layout/PageTitle';
import { useNavigate } from 'react-router-dom';

interface ShipmentRighttPanelInterface {
  selectedShipment: Shipment | undefined;
  setSelectedShipment: React.Dispatch<React.SetStateAction<Shipment | undefined>>;
  setShipmentForm: React.Dispatch<React.SetStateAction<ShipmentFormInt>>;
}

const ShipmentRightPanel: React.FC<ShipmentRighttPanelInterface> = ({
  selectedShipment,
  setSelectedShipment,
  setShipmentForm,
}) => {
  if (!selectedShipment) return null;
  return (
    <div style={{ paddingTop: '10px', paddingRight: '10px', paddingLeft: '10px' }}>
      <PageTitle
        title={`Shipment: ${selectedShipment.contractNumber}`}
        rightIcon={(
          <Button
            size='small'
            variant='outlined'
            onClick={() => {
              setShipmentForm({
                isOpen: true,
                shipment: selectedShipment,
              });
            }}
          >
            Edit
          </Button>
        )}
      />
      <Box component={Paper} variant='outlined' marginTop={1}>
        <Grid container>
          <Grid item xs={6} md={3}>
            <Box p={1}>
              <Typography variant='caption' display='block' fontWeight='bold'>Vessel</Typography>
              <Typography variant='body2'>{selectedShipment.vessel}</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box p={1}>
              <Typography variant='caption' display='block' fontWeight='bold'>Port Of Loading</Typography>
              <Typography variant='body2'>{selectedShipment.portOfLoading}</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box p={1}>
              <Typography variant='caption' display='block' fontWeight='bold'>Port Of Discharge</Typography>
              <Typography variant='body2'>{selectedShipment.portOfDischarge}</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box p={1}>
              <Typography variant='caption' display='block' fontWeight='bold'>Bill Of Lading</Typography>
              <Typography variant='body2'>{selectedShipment.billOfLadingNumber}</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box p={1}>
              <Typography variant='caption' display='block' fontWeight='bold'>Hazardous</Typography>
              <Typography variant='body2'>{selectedShipment.hazardous ? 'Yes' : 'No'}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ParcelList shipment={selectedShipment} />
    </div>
  );
};

export default ShipmentRightPanel;

interface ParcelListInterface {
  shipment: Shipment;
}

const ParcelList: React.FC<ParcelListInterface> = ({
  shipment
}) => {
  const { parcels } = useFetchParcelsByShipment(shipment._id);

  return (
    <Box component={Paper} variant='outlined' marginTop={1} p={1}>
      <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ marginBottom: 1 }}>
        <Typography variant='body1' fontWeight='bold'>Parcels</Typography>
        <Button
          variant='outlined'
          size='small'
          onClick={() => {
            const worksheetName = `Shipment ${shipment.contractNumber}`;
            const colNames = [
              'Parcels',
              'courierCompany',
              'Cartons',
              'SensitiveCargo',
            ];
            const filePath = `${worksheetName}.xlsx`;
            const transformedData = parcels.map((parcel) => [
              parcel.trackingNumber,
              parcel.courierCompany,
              parcel.articleMeasurements?.length || '-',
              parcel.sensitiveCargo ? 'Yes' : 'No',
            ]);
            const worksheetData = [colNames, ...transformedData];
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
            XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName);
            XLSX.writeFile(workbook, filePath);
            // XLSX.writeFile(workbook, path.resolve(filePath));
          }}
        >
          Export List
        </Button>
      </Stack>
      <TableContainer component={Paper} variant='outlined'>
        <Table aria-label="collapsible table" size='small'>
          <TableHead>
            <TableRow>
              <TableCell width={'25%'}>
                <Typography variant='caption' fontWeight='bold'>Tracking number</Typography>
              </TableCell>
              <TableCell width={'20%'}>
                <Typography variant='caption' fontWeight='bold'>Client</Typography>
              </TableCell>
              <TableCell width={'20%'}>
                <Typography variant='caption' fontWeight='bold'>Order</Typography>
              </TableCell>
              <TableCell width={'20%'} align='center'>
                <Typography variant='caption' fontWeight='bold'>Articles</Typography>
              </TableCell>
              <TableCell width={'15%'} align='right'>
                <Typography variant='caption' fontWeight='bold'>Sensitive Cargo</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              parcels.map((parcel: ParcelDetailed, key: number) => (
                <Fragment key={`displayParcel-${key}-${parcel._id}`}>
                  <ParcelListRow parcel={parcel} />
                </Fragment>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

interface ParcelListRowInterface {
  parcel: ParcelDetailed;
}

const ParcelListRow: React.FC<ParcelListRowInterface> = ({
  parcel,
}) => {
  const navigate = useNavigate();

  return (
    <TableRow>
      <TableCell>
        <Typography
          variant='body2'
          fontWeight='bold'
          color='secondary'
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            navigate(`/parcels`, { state: { parcel: parcel._id } });
          }}
        >
          {parcel.trackingNumber}
        </Typography>
        <Typography variant='caption' fontWeight='bold'>{parcel.courierCompany}</Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant='body2'
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            // navigate(`/clients`, { state: { client: (parcel.client as User)._id } });
          }}
        >
          {(parcel.client as User).username}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant='body2'
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            // navigate(`/orders`, { state: { order: (parcel.order as Order)._id } });
          }}
        >
          kjnk
          {/* {(parcel.order as Order).name} */}
        </Typography>
      </TableCell>
      <TableCell align='center'>
        <Typography variant='body2'>{parcel.articleMeasurements?.length || '-'}</Typography>
      </TableCell>
      <TableCell align='right'>
        <Typography variant='body2' color={parcel.sensitiveCargo ? 'red' : ''}>{parcel.sensitiveCargo ? 'Yes' : 'No'}</Typography>
      </TableCell>
    </TableRow>
  );
};
