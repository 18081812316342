import React, { useContext, useEffect, useRef } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import jwtDecode, { JwtPayload } from 'jwt-decode';

import ContextApp from '../../contexts/ContextApp';
import { isAuthenticated, refreshToken, logout } from '../helpers/authentication';

interface PrivateRouteProps {
  // path: string;
  // element: JSX.Element;
  children: JSX.Element;
}

// const PrivateRoute = ({ path, element }: PrivateRouteProps) => {
const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const { pathname } = useLocation();
  const contextApp = useContext(ContextApp);
  const { authState, authDispatch } = contextApp;
  const isAuth = isAuthenticated(contextApp);

  const sessionTimeout = useRef<NodeJS.Timeout | undefined>();

  const clearSession = () => {
    if (sessionTimeout.current) {
      clearTimeout(sessionTimeout.current);
      sessionTimeout.current = undefined;
    }
  };

  useEffect(() => {
    clearSession();
    if (authState.accessToken !== '') {
      const { iat = 0, exp = 0, iss } = jwtDecode<JwtPayload>(authState.accessToken);
      if (iss !== 'feathers') return;
      const MILLISECONDS = 1000;
      const THRESHOLD = 200;
      sessionTimeout.current = setTimeout(() => {
        logout(authDispatch);
        window.location.replace(process.env.REACT_APP_APPCLIENT_URL || 'http://localhost:3001');
      }, (exp - iat) * MILLISECONDS - THRESHOLD); // logout 0.2 seconds before session expiry
    }
  }, [authState.accessToken, authDispatch]);

  useEffect(() => {
    const listener = () => refreshToken(contextApp.authDispatch);
    
    const events = ['load', 'click', 'keypress'];
    events.forEach((wEvent) => window.addEventListener(wEvent, listener));
    return () => {
      events.forEach((wEvent) => window.removeEventListener(wEvent, listener));
    }
  }, [contextApp.authDispatch]);

  if (!isAuth) return <Navigate to="/login" state={{ from: pathname }} />
  return children;
}

export default PrivateRoute;
