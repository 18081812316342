import React, { useState, useContext, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import {
  ThemeProvider, createTheme,
  AppBar, Container, Toolbar, Typography, Box, Tooltip, IconButton, Button, Menu, MenuItem,
} from '@mui/material';
import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import LoadingSpinner from '../utility/LoadingSpinner';

import AppMenuBar from './AppMenuBar';
import PrivateRoute from './PrivateRoute';
import LandingPage from '../pages/landing/LandingViewer';
import DashboardPage from '../pages/dashboard/DashboardViewer';
import SampleViewer from '../pages/sample/SampleViewer';
import LoginPage from '../pages/authentication/LoginPage';
import SignupPage from '../pages/authentication/SignupPage';

import ContextApp from '../../contexts/ContextApp';
import { isAuthenticated, logout } from '../helpers/authentication';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#f48fb1',
    },
  },
});

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
});

const AppRoot = React.lazy(() => import('../layout/AppRoot'));

const AppRouter: React.FC = () => {
  const theme = lightTheme;

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AppMenuBar />
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            <Route
              path='/login'
              element={<LoginPage />}
            />
            <Route
              path='/*'
              element={
                <PrivateRoute>
                  <AppRoot />
                </PrivateRoute>
              }
            />
            <Route
              path='/samples/*'
              element={<SampleViewer />}
            />
          </Routes>
        </Suspense>
      </Router>
    </ThemeProvider>
  );
};

export default AppRouter;
