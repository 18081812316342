import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AppManagement from './AppManagement';

const AppManagementViewer: React.FC = (): any => (
  <div style={{ width: '100%', height: '100%' }}>
    <Routes>
      <Route
        path='/'
        element={<AppManagement />}
      />
    </Routes>
  </div>
);

export default AppManagementViewer;
