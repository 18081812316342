import React from 'react';
import { IconButton, Tooltip, } from '@mui/material';
import { Cancel } from '@mui/icons-material';

import PageTitle from '../../layout/PageTitle';
import { ManagementItemInterface } from './AppManagement';
import ManageWorkspaces from './ManagementItems/ManageWorkspaces';
import ManageUsers from './ManagementItems/ManageUsers';

interface AppManagementRightPanelInterface {
  selectedManagementItem: ManagementItemInterface | undefined;
  setSelectedManagementItem: React.Dispatch<React.SetStateAction<ManagementItemInterface | undefined>>;
}

const AppManagementRightPanel: React.FC<AppManagementRightPanelInterface> = ({
  selectedManagementItem,
  setSelectedManagementItem,
}) => {
  const renderManageComponent = () => {
    switch(selectedManagementItem?.id) {
      case 'workspaces':
        return <ManageWorkspaces />;
      case 'users':
        return <ManageUsers />
    };
    return null;
  };
  if (selectedManagementItem === undefined) return null;
  return (
    <div style={{ padding: '10px' }}>
      <PageTitle
        title={selectedManagementItem.title}
        rightIcon={(
          <IconButton onClick={() => setSelectedManagementItem(undefined)}>
            <Tooltip title='Close'>
              <Cancel />
            </Tooltip>
          </IconButton>
        )}
      />
      {renderManageComponent()}
    </div>
  );
};

export default AppManagementRightPanel;
