import React, { useState, useEffect } from 'react';
import {
  Box, Paper, Typography, Button,
  Grid, IconButton, Tooltip,
  TableContainer, Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import FormTextField from '../../../utility/forms/FormTextField';
import { createUser, usersFindAcWs, userFeathersService } from '../../../feathers/services/User.service';
import { UserAdmin } from '../../../feathers/server-interface';

const ManageUsers = () => {
  const [users, setUsers] = useState<UserAdmin[]>([]);
  const [selectedUser, selectedSelectedUser] = useState<UserAdmin | undefined>(undefined);

  useEffect(() => {
    const init = async () => {
      try {
        const foundWsUsers = await usersFindAcWs();
        setUsers(foundWsUsers); 
      } catch (error) {
        
      }
    };
    init();
  }, []);

  useEffect(() => {
    const handleUserCreate = (createdUser: UserAdmin) => {
      console.log('createdUser: ', createdUser);
      if (createdUser.acessableWs) {
        setUsers((prevState: UserAdmin[]) => [createdUser, ...prevState]);
      }
    };

    userFeathersService.on('created', handleUserCreate);

    return () => {
      userFeathersService.removeListener('created', handleUserCreate);
    }
  }, []);

  const schema = Yup.object().shape({
    username: Yup.string().required('Name is required'),
    password: Yup.string().required('Password is required'),
    email: Yup.string().required('Email is required'),
  });

  return (
    <div>
      <Box
        component={Paper}
        sx={{ padding: '10px', marginBottom: '10px' }}
      >
        <Typography>{selectedUser ? 'Edit' : 'Create'} User</Typography>
        <Formik
          enableReinitialize
          validationSchema={schema}
          initialValues={{
            _id: selectedUser ? selectedUser._id : undefined,
            username: selectedUser ? selectedUser.username : '',
            email: selectedUser ? selectedUser.email : '',
            password: selectedUser ? selectedUser.password : '',
            acessableWs: true,
            defaultWorkspace: '',
          }}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(true);
            try {
              if (selectedUser) {
                // Patch
              } else {
                // Create
                await createUser(values);
              }
            } catch (error) {
              console.log('error: ', error);
            }
            actions.setSubmitting(false);
          }}
        >
          {({
            isSubmitting,
            errors,
            submitForm,
            dirty,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
            setTouched,
            resetForm,
          }) => (
            <Form>
              <Field
                component={FormTextField}
                name='username'
                type='text'
                label='Username'
                required
              />
              <Field
                component={FormTextField}
                name='email'
                type='text'
                label='Email'
                required
              />
              <Field
                component={FormTextField}
                name='password'
                type='password'
                label='Password'
                required
              />
              <Button
                variant='contained'
                sx={{ width: '100%' }}
                type='submit'
                disabled={isSubmitting || !dirty || (Object.keys(errors).length > 0)}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
      <TableContainer variant='outlined' component={Paper}>
        <Table sx={{ }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Roles</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              users.map((user) => (
                <TableRow key={user._id}>
                  <TableCell>
                    <Typography>
                      {user.username}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{user.email}</Typography>
                  </TableCell>
                  <TableCell>Normal</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ManageUsers;
