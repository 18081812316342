import React, { useEffect, useState } from 'react';
import { Typography, Button, MenuItem } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { Select } from 'formik-mui';
import * as Yup from 'yup';

import FormTextField from '../../../utility/forms/FormTextField';
import FormSelect from '../../../utility/forms/FormSelect';
import FormSelectMulti from '../../../utility/forms/FormSelectMulti';
import FormSwitch from '../../../utility/forms/FormSwitch';
import { DropzoneArea } from 'mui-file-dropzone';
import textfile from '../testbase64.js';

import FeathersApp, { Service } from '../../../feathers/Feathers';

import TestFile  from '../testbase64';

const SampleForm: React.FC = () => {
  const [sample, setSample] = useState(textfile);

  const schema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    // shelveId: Yup.string().required('Shelve is required.'),
    quantity: Yup.number().typeError("Must be a number.")
      .positive('Negative numbers not allowed.').integer('Must be a whole number'),
      // .required("Quantity is required."),
    choices: Yup.array().required('Choices is required'),
  });

  // Get files here
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

  // const toBase64 = (file: any) => new Promise((resolve, reject) => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = () => resolve(reader.result);
  //   reader.onerror = error => reject(error);
  // });
  const toBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader && reader.result) {
          let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
          if ((encoded.length % 4) > 0) {
            encoded += '='.repeat(4 - (encoded.length % 4));
          }
          resolve(encoded);
        }
      };
      reader.onerror = error => reject(error);
    });
  };

  return (
    <div>
      <Typography variant='h5'>Sample Form</Typography>
      <button
        type='button'
        onClick={async () => {
          const storageResult = await FeathersApp.service(Service.Storage).get("642aa04b32fe522be06174b3");
          console.log('storageResult: ', storageResult);
        }}
      >
        Click
      </button>
      <Formik
        enableReinitialize
        validationSchema={schema}
        initialValues={{
          name: '',
          password: '',
          quantity: '',
          age: '',
          age2: [],
          agree: false,
          choices: [{ value: '1', label: 'Item 1' }, { value: '3', label: 'Item 3' }],
        }}
        onSubmit={async (values, actions) => {
          // console.log(values);
          // console.log('uploadedFiles: ', uploadedFiles);
          try {
            actions.setSubmitting(false);
            const filedata = await Promise.all(uploadedFiles.map(async (file: any) => ({
              module: 'parcels',
              belongTo: 'ID',
              fileName: file.name,
              fileSize: file.size,
              fileType: file.type,
              uri: await Promise.resolve(toBase64(file)),
              // uri: 'TestFile',
            })));
            console.log(values);
            // console.log(uploadedFiles);
            // console.log('filedata: ', filedata[0]);
            // console.log('filedata type: ', typeof TestFile);
            // const storageResult = await FeathersApp.service(Service.Storage).create(filedata[0]);
            // console.log('storageResult: ', storageResult);
            // const test = await createUpload(filedata[0]);
            // const test = await getUpload();
          } catch (error) {
            console.log('error: ', error);
          }
          actions.setSubmitting(false);
        }}
      >
        {({
          isSubmitting,
          errors,
          submitForm,
          dirty,
          touched,
          handleBlur,
          handleChange,
          setFieldValue,
          setTouched,
          resetForm,
        }) => (
          <Form>
            <Field
              component={FormTextField}
              name='name'
              type='text'
              label='Name'
              required
            />
            <Field
              component={FormTextField}
              name='password'
              type='password'
              label='Password'
            />
            <Field
              component={FormTextField}
              name='quantity'
              type='number'
              label='Quantity'
            />
            <Field
              component={FormSwitch}
              name='agree'
              type='checkbox'
              label='Agree'
            />
            <Field
              component={FormSelectMulti}
              name="age2"
              label="Age2 Multi"
              options={[
                { value: '10', label: 'Ten' },
                { value: '20', label: 'Twenty' },
                { value: '30', label: 'Thirty' },
              ]}
            />
            <Field
              component={FormSelect}
              name="age"
              label="Age"
              options={[
                { value: '10', label: 'Ten' },
                { value: '20', label: 'Twenty' },
                { value: '30', label: 'Thirty' },
              ]}
              validate={(age: number) =>
                !age
                  ? 'Please enter your age'
                  : age < 21
                  ? 'You must be 21 or older'
                  : undefined
              }
            />
            {/* <Field
              component={Select}
              formControl={{ sx: { width: '100%', marginBottom: '10px' } }}
              // formHelperText={{ children: 'How old are you?' }}
              // InputLabelProps={{
              //   shrink: true,
              // }}
              variant='standard'
              id="age"
              name="age"
              labelId="age-simple"
              label="Age"
              validate={(age: number) =>
                !age
                  ? 'Please enter your age'
                  : age < 21
                  ? 'You must be 21 or older'
                  : undefined
              }
            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Field> */}
            {/* export interface FileObject {
              readonly file: File;
              readonly data: string | ArrayBuffer | null | undefined;
            } */}
            <DropzoneArea
              onChange={(files) => {
                setUploadedFiles(files);
              }}
              fileObjects={[]}
              acceptedFiles={["image/jpeg", "image/png", "image/bmp", "application/pdf"]}
              showFileNamesInPreview={true}
              showFileNames={true}
              initialFiles={[
                `data:image/png;base64,${sample}`
              ]}
              filesLimit={3}
              // maxFileSize={}
            />
            <Button
              variant='contained'
              sx={{ width: '100%' }}
              type='submit'
              disabled={isSubmitting || !dirty || (Object.keys(errors).length > 0)}
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SampleForm;
