import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import LeftRightPanel from '../../layout/LeftRightPanel';
import ParcelsLeftPanel from './ParcelsLeftPanel';
import ParcelsRightPanel from './ParcelsRightPanel';
import ContextApp from '../../../contexts/ContextApp';
import { getParcel } from '../../feathers/services/Parcel.service';
import { Parcel } from '../../feathers/server-interface';

const Parcels: React.FC = () => {
  const { state } = useLocation();
  const { authState } = useContext(ContextApp);
  const [selectedParcel, setSelectedParcel] = useState<Parcel | undefined>(undefined);

  useEffect(() => {
    setSelectedParcel(undefined);
  }, [authState.defaultWorkspace])

  useEffect(() => {
    if (state) {
      const parcelId = state.parcel as unknown as string;
      const getSelectedParcel = async () => {
        const foundShipment = await getParcel(parcelId);
        setSelectedParcel(foundShipment);
      }
      getSelectedParcel();
    }
  }, [state]);

  return (
    <LeftRightPanel
      leftPanelComponent={(
        <ParcelsLeftPanel
          selectedParcel={selectedParcel}
          setSelectedParcel={setSelectedParcel}
        />
      )}
      rightPanelComponent={(
        <ParcelsRightPanel
          selectedParcel={selectedParcel}
          setSelectedParcel={setSelectedParcel}
        />
      )}
    />
  );
};

export default Parcels;
