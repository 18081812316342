import React, { useState } from 'react';
import {
  Button, Box,
  Dialog, DialogTitle,DialogContent, DialogActions, Typography,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormTextField from '../../utility/forms/FormTextField';
import FormSwitch from '../../utility/forms/FormSwitch';
import { createShipment, patchShipment } from '../../feathers/services/Shipment.service';
import { Shipment } from '../../feathers/server-interface';

export interface ShipmentFormInt {
  isOpen: boolean;
  shipment: Shipment | undefined;
}

export interface ShipmentFormInterface {
  shipmentForm: ShipmentFormInt;
  setShipmentForm: React.Dispatch<React.SetStateAction<ShipmentFormInt>>;
}

const ShipmentForm: React.FC<ShipmentFormInterface> = ({
  shipmentForm,
  setShipmentForm,
}) => {
  const { isOpen, shipment } = shipmentForm;
  const [errorMessage, setErrorMessage] = useState<string>('');
  const handleClose = () => {
    setShipmentForm({
      isOpen: false,
      shipment: undefined,
    });
  };

  const schema = Yup.object().shape({
    // weight: Yup.string().required('Weight is required'),
  });

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth>
      <DialogTitle>{!shipment ? 'Create Shipment' : `Edit ${(shipment as unknown as Shipment).contractNumber}`}</DialogTitle>
      <Formik
        enableReinitialize
        validationSchema={schema}
        initialValues={{
          _id: shipment?._id ? shipment._id : undefined,
          contractNumber: shipment ? shipment.contractNumber : '',
          vessel: shipment ? shipment.vessel : '',
          portOfLoading: shipment ? shipment.portOfLoading : '',
          portOfDischarge: shipment ? shipment.portOfDischarge : '',
          billOfLadingNumber: shipment ? shipment.billOfLadingNumber : '',
          hazardous: shipment ? shipment.hazardous : false,
          containers: shipment ? shipment.containers : [],
        }}
        onSubmit={async (values, actions) => {
          try {
            setErrorMessage('');
            if (shipment?._id) {
              // Patch
              await patchShipment(shipment._id, values);
            } else {
              // Create
              await createShipment(values);
            }
            handleClose();
          } catch (error: any) {
            setErrorMessage(error.message);
          }
        }}
      >
        {({
          isSubmitting,
          errors,
          submitForm,
          dirty,
          touched,
          handleBlur,
          handleChange,
          setFieldValue,
          setTouched,
          resetForm,
        }) => (
          <>
            <DialogContent>
              <Form>
                <Field
                  component={FormTextField}
                  name='contractNumber'
                  type='text'
                  label='Contract Number'
                  required
                />
                <Field
                  component={FormTextField}
                  name='vessel'
                  type='text'
                  label='Vessel'
                  required
                />
                <Field
                  component={FormTextField}
                  name='portOfLoading'
                  type='text'
                  label='Port Of Loading'
                  required
                />
                <Field
                  component={FormTextField}
                  name='portOfDischarge'
                  type='text'
                  label='Port Of Discharge'
                  required
                />
                <Field
                  component={FormTextField}
                  name='billOfLadingNumber'
                  type='text'
                  label='Bill Of Lading Number (B/L)'
                />
                <Field
                  component={FormSwitch}
                  name='hazardous'
                  type='checkbox'
                  label='Hazardous'
                />
              </Form>
              <Box sx={{ width: '100%', paddingBottom: 1, textAlign: 'center' }}>
                <Typography variant='caption' color='red'>
                  {errorMessage}
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} variant='outlined'>Cancel</Button>
              <Button onClick={submitForm} variant='contained'>Submit</Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default ShipmentForm;
