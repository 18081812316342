import React from 'react';
import { Typography } from '@mui/material';

const SampleForm: React.FC = () => {

  return (
    <div>
      <Typography variant='h5'>Sample Typography</Typography>
    </div>
  );
};

export default SampleForm;
