import { useState, useEffect } from 'react';

import { findParcelsByShipment, parcelFeathersService } from '../../../feathers/services/Parcel.service'
import { ParcelDetailed, Shipment } from '../../../feathers/server-interface'

const useFetchParcelsByShipment = (shipmentId: string) => {
  const [parcels, setParcels] = useState<ParcelDetailed[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      try {
        const fetchedShipmentParcels = await findParcelsByShipment(shipmentId);
        setParcels(fetchedShipmentParcels); 
      } catch (error: any) {
        setError(error.message);
      }
      setIsLoading(false);
    }
    init();
  }, [shipmentId]);

  useEffect(() => {
    const handleParcelCreate = (createdParcel: ParcelDetailed) => {
      if ((createdParcel.shipment as Shipment)?._id === createdParcel._id) {
        setParcels((prevState: ParcelDetailed[]) => {
          return [createdParcel, ...prevState];
        });
      }
    };

    const handleParcelPatch = (patchedParcel: ParcelDetailed) => {
      if ((patchedParcel.shipment as Shipment)?._id === patchedParcel._id) {
        setParcels((prevState: ParcelDetailed[]) => prevState.map((prevParcel) => {
          if (prevParcel._id === patchedParcel._id) return patchedParcel;
          return prevParcel;
        }));
      }
    };

    parcelFeathersService.on('created', handleParcelCreate);
    parcelFeathersService.on('patched', handleParcelPatch);

    return () => {
      parcelFeathersService.removeListener('created', handleParcelCreate);
      parcelFeathersService.removeListener('patched', handleParcelPatch);
    }
  }, []);

  return {
    parcels,
    isLoading,
    error,
  };
};

export default useFetchParcelsByShipment;
