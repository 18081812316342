import React, { useState, useRef } from 'react';
import { Typography, Card, CardContent, } from '@mui/material';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';

import PageTitle from '../../layout/PageTitle';
import SearchBar from '../../utility/SearchBar';
import useDebounce from '../../helpers/hooks/useDebounce';
import { ManagementItemInterface } from './AppManagement';

interface AppManagementLeftPanelInterface {
  managementItems: ManagementItemInterface[];
  selectedManagementItem: ManagementItemInterface | undefined;
  setSelectedManagementItem: React.Dispatch<React.SetStateAction<ManagementItemInterface | undefined>>;
}

const AppManagementLeftPanel: React.FC<AppManagementLeftPanelInterface> = ({
  managementItems,
  selectedManagementItem,
  setSelectedManagementItem,
}) => {
  const virtuoso = useRef<VirtuosoHandle | null>(null);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearchQuery = useDebounce(searchQuery, 250);
  const searchBarRef: any = useRef();

  return (
    <>
      <PageTitle title='App Manager' />
      <SearchBar
        defaultPlaceholder='Search Item Number'
        defaultValue={debouncedSearchQuery}
        onChange={(value: string) => setSearchQuery(value)}
        // setOnFocus={setDisplayList}
        ref={searchBarRef}
      />
      <div style={{ width: '100%', height: 'calc(100% - 64px)' }}>
        <Virtuoso
          ref={virtuoso}
          data={managementItems}
          // endReached={loadMore}
          totalCount={managementItems.length}
          itemContent={(
            index,
            managementItem
          ) => <AppManagementListCardItem
            managementItem={managementItem}
            selectedManagementItem={selectedManagementItem}
            setSelectedManagementItem={setSelectedManagementItem}
          />}
        />
      </div>
    </>
  );
};

export default AppManagementLeftPanel;

interface AppManagementListCardItem {
  managementItem: ManagementItemInterface;
  selectedManagementItem: ManagementItemInterface | undefined;
  setSelectedManagementItem: React.Dispatch<React.SetStateAction<ManagementItemInterface | undefined>>;
}

const AppManagementListCardItem: React.FC<AppManagementListCardItem> = ({
  managementItem,
  selectedManagementItem,
  setSelectedManagementItem,
}) => {
  return (
    <Card
      // className={`${classes.root} ${selectedShelve?._id === shelve._id ? classes.selected : ''}`}
      sx={{
        // width: '100%',
        height: '100px',
        marginBottom: '10px',
        cursor: 'pointer',
        borderColor: managementItem.id === selectedManagementItem?.id ? '#1976d2' : '',
      }}
      variant='outlined'
      onClick={() => setSelectedManagementItem(managementItem)}
    >
      <CardContent>
        <Typography variant='h6'>{managementItem.title}</Typography>
      </CardContent>
    </Card>
  );
};
