import React, { useState } from 'react';
import {
  Typography, IconButton, Tooltip,
  MenuItem, Menu,
} from '@mui/material';
import { More } from '@mui/icons-material';

interface OptionsInterface {
  optionTitle: string;
  optionAction: () => void;
}

interface PageTitleInterface {
  title: string | JSX.Element;
  titleColor?: string; 
  moreOptions?: OptionsInterface[];
  rightIcon?: any;
}

const PageTitle: React.FC<PageTitleInterface> = ({
  title,
  titleColor,
  moreOptions,
  rightIcon,
}) => {
  const [anchorEl, setAnchorEl] = useState<any>();
  const generateOptionButtons = () => moreOptions?.map((item: OptionsInterface) => (
    <MenuItem
      key={item.optionTitle}
      sx={{ minWidth: '180px' }}
      onClick={() => {
        setAnchorEl(null);
        item.optionAction();
      }}
    >
      {item.optionTitle}
    </MenuItem>
  ));


  return (
    <div style={{ width: '100%', display: 'flex', paddingBottom: '0', alignItems: 'center' }}>
      <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'left', alignItems: 'left' }}>
        {
          typeof title === 'string' ? (
            <Typography
              variant="h5"
              style={{ color: titleColor }}
            >
              {title}
            </Typography>
          ) : title
        }
      </div>
      <div style={{ display: !!moreOptions ? 'flex' : 'none', alignItems: 'center', justifyContent: 'flex-end' }}>
        <IconButton size='small' onClick={(event) => setAnchorEl(event.currentTarget)}>
          <Tooltip title='Actions'>
            <More />
          </Tooltip>
        </IconButton>
        <Menu
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          { generateOptionButtons() }
        </Menu>
      </div>
      <div style={{ display: !!rightIcon ? 'flex' : 'none', alignItems: 'center', justifyContent: 'flex-end' }}>
        { rightIcon }
      </div>
    </div>
  );
};

export default PageTitle;
