import { Select, SelectProps } from 'formik-mui';
import { Typography, Button, MenuItem, Menu } from '@mui/material';

interface OptionInterface {
  value: string;
  label: string;
}

interface FormTextFieldInterface extends SelectProps {
  options: OptionInterface[];
}

const FormSelect = (props: FormTextFieldInterface) => {
  const { required, options, ...rest } = props;
  const generatedOptions = () => options.map((option) => (
    <MenuItem key={`MenuOption-${option.value}-${option.label}`} value={option.value}>{option.label}</MenuItem>
  ));

  return (
    <>
      <Select
        variant='standard'
        formControl={{ sx: { width: '100%', marginBottom: '10px' } }}
        // formHelperText={{ children: 'How old are you?' }}
        inputLabel={{
          shrink: true,
        }}
        {...rest}
      >
        {generatedOptions()}
      </Select>
    </>
  );
};

export default FormSelect;
