import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
  Grid, Box, Paper,
  IconButton, Tooltip, Typography,
  Chip, Stack, Stepper, Step,
  StepLabel, Button,
  Dialog, DialogTitle,DialogContent, DialogContentText, DialogActions, InputAdornment,
  Table, TableContainer, TableBody, TableHead, TableRow, TableCell,
} from '@mui/material';
import { Formik, Form, Field, FieldProps } from 'formik';
import FormTextField from '../../utility/forms/FormTextField';
import * as Yup from 'yup';
import { Cancel, Edit, Delete } from '@mui/icons-material';

import AddToShipmentDialog, { AddToShipmentDialogInt } from '../Shipments/components/AddToShipmentDialog';
import AddToOrderDialog, { AddToOrderDialogInt } from '../Orders/components/AddToOrderDialog';
import ContextApp from '../../../contexts/ContextApp';
import PageTitle from '../../layout/PageTitle';
import { toDateTimeString } from '../../utility/DateTimeHelpers';
import { IsMobileView } from '../../helpers/hooks/useMediaQuery';
import { findParcelsByModule, getUploadById } from '../../feathers/services/Upload.service';
import { parcelRecievedAtForwardingWarehouse, addParcleArticle, patchParcleArticle } from '../../feathers/services/Parcel.service';
import { ParcelDetailed, Upload, User, Workspace, ParcelArticleMeasurement, Shipment, Order } from '../../feathers/server-interface';
import { CustomFileType, toBlob } from '../../utility/forms/UploadFileField';
import { useNavigate } from 'react-router-dom';

interface ParcelsRightPanelInterface {
  selectedParcel: ParcelDetailed | undefined;
  setSelectedParcel: React.Dispatch<React.SetStateAction<ParcelDetailed | undefined>>;
}

const ParcelsRightPanel: React.FC<ParcelsRightPanelInterface> = ({
  selectedParcel,
  setSelectedParcel,
}) => {
  const isMobileView = IsMobileView();
  const { authState } = useContext(ContextApp);
  const [uploadedFilesEmpty, setUploadedFilesEmpty] = useState<Upload[]>([]);

  useEffect(() => {
    if (selectedParcel) {
      const findInitialUploads = async () => {
        try {
          if (selectedParcel) {
            const foundUploads = await findParcelsByModule('parcels', selectedParcel._id, true);
            setUploadedFilesEmpty(foundUploads);
            // const blobUploads = foundUploads ? await uploadsToBlob(foundUploads) : [];
            // setUploadedFiles(blobUploads);
          }
        } catch (error: any) {
          console.log(error.message);
        }
        // finally {
        //   setInitialzed(true);
        // }
      };
      findInitialUploads();
    }
  }, [selectedParcel]);

  if (!selectedParcel) return null;
  return (
    <div style={{ padding: '10px' }}>
      <PageTitle
        title={`Tracking Number: ${selectedParcel.trackingNumber} (${selectedParcel.courierCompany})`}
        rightIcon={(
          <IconButton onClick={() => setSelectedParcel(undefined)}>
            <Tooltip title='Close'>
              <Cancel />
            </Tooltip>
          </IconButton>
        )}
      />
      {/* <Box sx={{ width: '100%', marginBottom: '10px' }}>
        <Stepper activeStep={Number(selectedParcel.status)}>
          <Step key={'0-AwaitingArr'}>
            <StepLabel>Pending Arrival</StepLabel>
          </Step>
          <Step key={'1-AwaitingArr'}>
            <StepLabel>Recieved Forwarding Warehouse</StepLabel>
          </Step>
          <Step key={'2-AwaitingArr'}>
            <StepLabel>On Route to Destination</StepLabel>
          </Step>
          <Step key={'3-AwaitingArr'}>
            <StepLabel>Arrived Destination</StepLabel>
          </Step>
          <Step key={'4-AwaitingArr'}>
            <StepLabel>Out for delivery</StepLabel>
          </Step>
          <Step key={'5-AwaitingArr'}>
            <StepLabel>Delivered</StepLabel>
          </Step>
        </Stepper>
      </Box> */}
      <Box
        component={Paper}
        variant='outlined'
        sx={{ padding: '10px', marginBottom: '10px' }}
      >
        <Grid container spacing={1}>
          <Grid item xl={6} md={6} xs={12}>
            {/* <Box
              component={Paper}
              variant='outlined'
              sx={{ padding: '10px', marginBottom: '10px' }}
            > */}
              <Typography variant='h6'>Client</Typography>
              <Grid container>
                <Grid item xl={6} md={6} xs={6}>
                  <Typography variant='caption'>Username:</Typography>
                  <Typography variant='body1'>{(selectedParcel.client as User).username}</Typography>
                </Grid>
                <Grid item xl={6} md={6} xs={6}>
                  <Typography variant='caption'>Email:</Typography>
                  <Typography variant='body1'>test@gmail.com</Typography>
                </Grid>
                <Grid item xl={12} md={12} xs={12}>
                  <Typography variant='caption'>Delivery Address:</Typography>
                  <Typography variant='body1'>
                    31 Jurong Port Road,
                    Singapore 568120
                  </Typography>
                </Grid>
              </Grid>
            {/* </Box> */}
          </Grid>
          <Grid item xl={6} md={6} xs={12}>
            {/* <Box
              component={Paper}
              variant='outlined'
              sx={{ padding: '10px', marginBottom: '10px' }}
            > */}
              <Grid container>
                <Grid item xl={6} md={6} xs={6}>
                  <Typography variant='h6'>Goods Information</Typography>
                </Grid>
                <Grid item xl={6} md={6} xs={6} sx={{textAlign: 'right'}}>
                  <Typography variant='caption'>Created On: {toDateTimeString(selectedParcel.createInfo?.dateTime)}</Typography>
                </Grid>
                <Grid item xl={6} md={6} xs={6}>
                  <Typography variant='caption'>Parcel Value:</Typography>
                  <Typography variant='body1'>${selectedParcel.parcelValue}</Typography>
                </Grid>
                <Grid item xl={6} md={6} xs={6}>
                  <Typography variant='caption'>Sensitive Cargo:</Typography>
                  <Typography variant='body1'>{!!selectedParcel.sensitiveCargo ? 'Yes' : 'No'}</Typography>
                </Grid>
                <Grid item xl={12} md={12} xs={12}>
                  <Typography variant='caption'>Remarks:</Typography>
                  <Typography variant='body1'>{selectedParcel.remarks || '-'}</Typography>
                </Grid>
              </Grid>
            {/* </Box> */}
          </Grid>
        </Grid>
      </Box>
      <Box
        component={Paper}
        variant='outlined'
        sx={{ padding: '10px', marginBottom: '10px' }}
      >
        <Typography>Documents/Files:</Typography>
        <Stack direction="row" spacing={1}>
          {
            uploadedFilesEmpty.map((file) => (
              <Chip
                key={file._id}
                label={file.fileName}
                onClick={async () => {
                  const foundUpload = await getUploadById(file._id);
                  const blob = await toBlob(foundUpload.uri, foundUpload.fileName, foundUpload.fileType);
                  var csvURL = window.URL.createObjectURL(blob);
                  var tempLink = document.createElement('a');
                  tempLink.href = csvURL;
                  tempLink.setAttribute('download', foundUpload.fileName);
                  tempLink.click();
                }}
              />
            ))
          }
        </Stack>
      </Box>
      {/* Parcel Have No Arrived Forwarding Warehouse, Display Button */}
      {
        authState.defaultWorkspace === (selectedParcel.forwardingWarehouse as Workspace)._id
        && !selectedParcel.arrivedForwardingWarehouse ? (
          <Button
            variant='contained'
            onClick={async () => {
              try {
                await parcelRecievedAtForwardingWarehouse(selectedParcel._id); 
              } catch (error) {
                console.log('error: ', error);
              }
            }}
          >
            Parcel recieved at {(selectedParcel.forwardingWarehouse as Workspace).name} warehouse
          </Button>
        ) : null
      }
      {/* Parcel Recieved at warehouse, display addition fields */}
      {
        (
          authState.defaultWorkspace === (selectedParcel.forwardingWarehouse as Workspace)._id
          || authState.defaultWorkspace === (selectedParcel.destinationWarehouse as Workspace)._id
        )
        && !!selectedParcel.arrivedForwardingWarehouse ? (
          <AdditionalParcelDetails selectedParcel={selectedParcel} />
        ) : null
      }
    </div>
  );
};

export default ParcelsRightPanel;

interface AdditionalParcelDetailsInterface {
  selectedParcel: ParcelDetailed;
}

const AdditionalParcelDetails: React.FC<AdditionalParcelDetailsInterface> = ({
  selectedParcel,
}) => {
  const navigate = useNavigate();
  const [measurementDialog, setMeasurementDialog] = useState<MeasurementDialogInt>({
    isOpen: false,
    parcelId: '',
    measurement: undefined,
  });
  const [addToShipmentDialog, setAddToShipmentDialog] = useState<AddToShipmentDialogInt>({
    isOpen: false,
    parcel: undefined,
  });
  const [addToOrderDialog, setAddToOrderDialog] = useState<AddToOrderDialogInt>({
    isOpen: false,
    parcel: undefined,
  });

  return (
    <Box>
      <Box>
        {
          !selectedParcel.articleMeasurements || selectedParcel?.articleMeasurements?.length < 1 ? (
            <Stack direction='row' justifyItems='center' alignItems='center'>
              <Typography>Number of articles/boxes: </Typography>
              <Button
                variant='outlined'
                size='small'
                onClick={() => {
                  setMeasurementDialog({
                    isOpen: true,
                    parcelId: selectedParcel._id,
                    measurement: undefined,
                  });
                }}
                sx={{marginBottom: 1, marginLeft: 1}}
              >
                Add Article/ Boxes
              </Button>
            </Stack>
          ) : (
            <>
              <Stack
                direction='row'
                justifyContent='space-between'
              >
                <>
                  <Typography>
                    Articles/ Boxes: {`${selectedParcel?.articleMeasurements?.length}`}pcs
                  </Typography>
                </>
                <Button
                  variant='outlined'
                  size='small'
                  onClick={() => {
                    setMeasurementDialog({
                      isOpen: true,
                      parcelId: selectedParcel._id,
                      measurement: undefined,
                    });
                  }}
                >
                  Add Article
                </Button>
              </Stack>
              <ParcelArticlesMeasurementsTable
                parcelId={selectedParcel._id}
                measurements={selectedParcel.articleMeasurements || []}
                measurementDialog={measurementDialog}
                setMeasurementDialog={setMeasurementDialog}
              />
            </>
          )
        }
        <Box>
          {
            !selectedParcel.shipment ? (
              <Stack direction='row' justifyItems='center' alignItems='center'>
                <Typography>Shipment: </Typography>
                <Button
                  variant='outlined'
                  size='small'
                  sx={{ marginLeft: '10px' }}
                  onClick={() => {
                    setAddToShipmentDialog({
                      isOpen: true,
                      parcel: selectedParcel,
                    });
                  }}
                >
                  Add to a shipment
                </Button>
              </Stack>
            ) : (
              <Box marginTop={1}>
                <Typography display='inline'>Shipment: </Typography>
                <Typography
                  display='inline'
                  color='blue'
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(`/shipments`, { state: { shipment: (selectedParcel.shipment as Shipment)._id } });
                  }}
                >
                  {(selectedParcel.shipment as Shipment).contractNumber}
                </Typography>
              </Box>
            )
          }
        </Box>
        <Box>
          {
            !selectedParcel.order ? (
              <Stack direction='row' justifyItems='center' alignItems='center' sx={{ marginTop: '10px' }}>
                <Typography>Order: </Typography>
                <Button
                  variant='outlined'
                  size='small'
                  sx={{ marginLeft: '10px' }}
                  onClick={() => {
                    setAddToOrderDialog({
                      isOpen: true,
                      parcel: selectedParcel,
                    });
                  }}
                >
                  Add to a order
                </Button>
              </Stack>
            ) : (
              <Box marginTop={1}>
                <Typography display='inline'>Order: </Typography>
                <Typography
                  display='inline'
                  color='blue'
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(`/orders`, { state: { order: (selectedParcel.order as Order)._id } });
                  }}
                >
                  {(selectedParcel.order as Order).name}
                </Typography>
              </Box>
            )
          }
        </Box>
      </Box>
      <MeasurementsDialog
        measurementDialog={measurementDialog}
        setMeasurementDialog={setMeasurementDialog}
      />
      <AddToShipmentDialog
        addToShipmentDialog={addToShipmentDialog}
        setAddToShipmentDialog={setAddToShipmentDialog}
      />
      <AddToOrderDialog
        addToOrderDialog={addToOrderDialog}
        setAddToOrderDialog={setAddToOrderDialog}
      />
    </Box>
  );
};

export interface ParcelArticlesMeasurementTableInterface {
  parcelId: string;
  measurements: ParcelArticleMeasurement[];
  measurementDialog: MeasurementDialogInt;
  setMeasurementDialog: React.Dispatch<React.SetStateAction<MeasurementDialogInt>>;
}

export const ParcelArticlesMeasurementsTable: React.FC<ParcelArticlesMeasurementTableInterface> = ({
  parcelId,
  measurements,
  setMeasurementDialog,
}) => {
  return (
    <TableContainer component={Paper} variant='outlined' sx={{ margin: '5px 0 20px 0' }}>
      <Table aria-label="measurement table" size='small'>
        <TableHead>
          <TableRow>
            <TableCell width={'21.25%'} align='left'>
              <Typography variant='caption' fontWeight='bold'>Weight</Typography>
            </TableCell>
            <TableCell width={'21.25%'} align='left'>
              <Typography variant='caption' fontWeight='bold'>Length</Typography>
            </TableCell>
            <TableCell width={'21.25%'} align='left'>
              <Typography variant='caption' fontWeight='bold'>Width</Typography>
            </TableCell>
            <TableCell width={'21.25%'} align='left'>
              <Typography variant='caption' fontWeight='bold'>Height</Typography>
            </TableCell>
            <TableCell width={'15%'} align='right'></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            measurements.map((measurement, key) => (
              <Fragment key={`measurement-${key}`}>
                <TableRow>
                  <TableCell>
                    <Typography variant='body2'>{measurement.weight}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='body2'>{measurement.length}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='body2'>{measurement.width}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='body2'>{measurement.height}</Typography>
                  </TableCell>
                  <TableCell align='right'>
                    <Stack direction='row' justifyContent='space-between'>
                      <IconButton size='small'>
                        <Tooltip title='Remove'>
                          <Delete fontSize='small' />
                        </Tooltip>  
                      </IconButton>
                      <IconButton size='small'
                        onClick={() => {
                          setMeasurementDialog({
                            isOpen: true,
                            parcelId: parcelId,
                            measurement: measurement,
                          });
                        }}
                      >
                        <Tooltip title='Edit'>
                          <Edit fontSize='small' />
                        </Tooltip>  
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              </Fragment>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export interface MeasurementDialogInt {
  isOpen: boolean;
  parcelId: string | undefined;
  measurement: ParcelArticleMeasurement | undefined;
}

export interface MeasurementDialogInterface {
  measurementDialog: MeasurementDialogInt;
  setMeasurementDialog: React.Dispatch<React.SetStateAction<MeasurementDialogInt>>;
}

export const MeasurementsDialog: React.FC<MeasurementDialogInterface> = ({
  measurementDialog,
  setMeasurementDialog,
}) => {
  const { isOpen, parcelId, measurement } = measurementDialog;
  const [errorMessage, setErrorMessage] = useState<string>('');
  const handleClose = () => {
    setMeasurementDialog({
      isOpen: false,
      parcelId: undefined,
      measurement: undefined,
    });
  };

  const schema = Yup.object().shape({
    weight: Yup.string().required('Weight is required'),
    length: Yup.string().required('Length is required'),
    width: Yup.string().required('Width is required'),
    height: Yup.string().required('Height is required'),
  });

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth>
      <DialogTitle>Set Measurements</DialogTitle>
        {/* <DialogContentText>
          kjhbjklm
        </DialogContentText> */}
        <Formik
          enableReinitialize
          validationSchema={schema}
          initialValues={{
            weight: measurement ? measurement.weight : '',
            length: measurement ? measurement.length : '',
            width: measurement ? measurement.width : '',
            height: measurement ? measurement.height : '',
          }}
          onSubmit={async (values, actions) => {
            try {
              // Push Measurement
              if (!measurement && parcelId) {
                const data = {
                  weight: values.weight,
                  length: values.length,
                  width: values.width,
                  height: values.height,
                };
                await addParcleArticle(parcelId, data);
              } else if (parcelId && measurement?._id) {
                // Patch Measurement
                const data = {
                  _id: measurement?._id,
                  weight: values.weight,
                  length: values.length,
                  width: values.width,
                  height: values.height,
                };
                await patchParcleArticle(parcelId, data);
                // console.log('res: ', res);
              }
              handleClose();
            } catch (error: any) {
              console.log('error: ', error);
              setErrorMessage(error.message);
            }
          }}
        >
          {({
            isSubmitting,
            errors,
            submitForm,
            dirty,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
            setTouched,
            resetForm,
          }) => (
            <>
              <DialogContent>
                <Form>
                  <Field
                    component={FormTextField}
                    name='weight'
                    type='text'
                    label='Weight'
                    required
                    InputProps={{
                      endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                    }}
                  />
                  <Field
                    component={FormTextField}
                    name='length'
                    type='text'
                    label='Length'
                    required
                    InputProps={{
                      endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                    }}
                  />
                  <Field
                    component={FormTextField}
                    name='width'
                    type='text'
                    label='Width'
                    required
                    InputProps={{
                      endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                    }}
                  />
                  <Field
                    component={FormTextField}
                    name='height'
                    type='text'
                    label='Height'
                    required
                    InputProps={{
                      endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                    }}
                  />
                  <Box sx={{ width: '100%', paddingBottom: 1, textAlign: 'center' }}>
                    <Typography variant='caption' color='red'>
                      {errorMessage}
                    </Typography>
                  </Box>
                </Form>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} variant='outlined'>Cancel</Button>
                <Button
                  // type='submit'
                  onClick={submitForm}
                  variant='contained'
                  disabled={isSubmitting || !dirty || (Object.keys(errors).length > 0)}
                >
                  Submit
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
    </Dialog>
  );
};

// const FormMeasurementsField = (props: FieldProps) => {
//   console.log('FormMeasurementsField props: ', props);
//   return (
//     <Box>

//     </Box>
//   );
// };
