import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Shipment from './Shipment';

const ShipmentViewer: React.FC = (): any => (
  <div style={{ width: '100%', height: '100%' }}>
    <Routes>
      <Route
        path='/'
        element={<Shipment />}
      />
    </Routes>
  </div>
);

export default ShipmentViewer;
