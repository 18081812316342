import React, { useContext, useEffect, useRef, useState } from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { Grid, Card, CardContent, Typography, Stack } from '@mui/material';
import { Warehouse, ArrowRightAlt } from '@mui/icons-material';

import ContextApp from '../../../contexts/ContextApp';
import PageTitle from '../../layout/PageTitle';
import SearchBar from '../../utility/SearchBar';
import useDebounce from '../../helpers/hooks/useDebounce';
import useFetchParcelsByWorkspace from './hooks/useFetchParcelsByWorkspace';
import { ParcelDetailed, Workspace } from '../../feathers/server-interface';

interface ParcelLeftPanelInterface {
  selectedParcel: ParcelDetailed | undefined;
  setSelectedParcel: React.Dispatch<React.SetStateAction<ParcelDetailed | undefined>>;
}

const ParcelsLeftPanel: React.FC<ParcelLeftPanelInterface> = ({
  selectedParcel,
  setSelectedParcel,
}) => {

  return (
    <>
      <PageTitle title='Parcels' />
      <ParcelList
        selectedParcel={selectedParcel}
        setSelectedParcel={setSelectedParcel}
      />
    </>
  );
};

export default ParcelsLeftPanel;

interface ParcelListInterface {
  selectedParcel: ParcelDetailed | undefined;
  setSelectedParcel: React.Dispatch<React.SetStateAction<ParcelDetailed | undefined>>;
}

const ParcelList: React.FC<ParcelListInterface> = ({
  selectedParcel,
  setSelectedParcel,
}) => {

  const virtuoso = useRef<VirtuosoHandle | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearchQuery = useDebounce(searchQuery, 250);
  const searchBarRef: any = useRef();

  const { authState } = useContext(ContextApp);
  const { parcels, loadMore } = useFetchParcelsByWorkspace(authState.defaultWorkspace as string, debouncedSearchQuery, selectedParcel, setSelectedParcel);

  useEffect(() => {
    if (document.getElementsByName("parcelsearch").length > 0) {
      document.getElementsByName("parcelsearch")[0].focus();
    }
  }, []);

  return (
    <>
      <SearchBar
        defaultPlaceholder='Search Item Number'
        defaultValue={debouncedSearchQuery}
        onChange={(value: string) => setSearchQuery(value)}
        // setOnFocus={setDisplayList}
        ref={searchBarRef}
        name='parcelsearch'
      />
      <div style={{ width: '100%', height: 'calc(100% - 64px)' }}>
        <Virtuoso
          ref={virtuoso}
          data={parcels?.data}
          endReached={loadMore}
          totalCount={parcels?.data.length}
          itemContent={(
            index,
            parcel,
          ) => <PanelListItem
            parcel={parcel}
            selectedParcel={selectedParcel}
            setSelectedParcel={setSelectedParcel}
          />}
        />
      </div>
    </>
  );
};

interface ParcelListItemInterface {
  parcel: ParcelDetailed;
  selectedParcel: ParcelDetailed | undefined;
  setSelectedParcel: React.Dispatch<React.SetStateAction<ParcelDetailed | undefined>>;
}

const PanelListItem: React.FC<ParcelListItemInterface> = ({
  parcel,
  selectedParcel,
  setSelectedParcel,
}) => {
  return (
    <Card
      // className={`${classes.root} ${selectedShelve?._id === shelve._id ? classes.selected : ''}`}
      sx={{
        // width: '100%',
        height: '100px',
        marginBottom: '10px',
        cursor: 'pointer',
        borderColor: parcel._id === selectedParcel?._id ? '#1976d2' : '',
      }}
      variant='outlined'
      onClick={() => setSelectedParcel(parcel)}
    >
      <CardContent>
        <Grid container>
          <Grid item xl={6} md={6} xs={6}>
            <Typography variant='caption'>Tracking Number:</Typography>
          </Grid>
          <Grid item xl={6} md={6} xs={6} sx={{ textAlign: 'right' }}>
            <Typography variant='caption'>{parcel.courierCompany}</Typography>
          </Grid>
        </Grid>
        <Typography variant='h6'>{parcel.trackingNumber}</Typography>
        <Stack direction='row' alignItems='center'>
          <Warehouse sx={{ fontSize: '15px', paddingBottom: '3px' }} />
          <Typography variant='caption' marginLeft={1}>
            {(parcel.forwardingWarehouse as Workspace).name}
          </Typography>
          <ArrowRightAlt sx={{ fontSize: '15px' }} />
          <Typography variant='caption' marginLeft={0}>
            {(parcel.destinationWarehouse as Workspace).name}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};
