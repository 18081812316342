import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  SwipeableDrawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText,
  AppBar, Container, Toolbar, Typography, Box, Tooltip, IconButton, Button, Menu, MenuItem, Divider,
} from '@mui/material';
import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import InboxIcon from '@mui/icons-material/MoveToInbox';

import ContextApp from '../../contexts/ContextApp';
import { isAuthenticated, logout } from '../helpers/authentication';
import SelectWorkspace from './SelectWorkspace';
import Modules from './Modules';

const AppMenuBar: React.FC = () => {
  const contextApp = useContext(ContextApp);
  const { authState } = contextApp;
  const authenticated = isAuthenticated(contextApp);

  const [leftDrawerOpen, setLeftDrawerOpen] = useState<boolean>(false);
  const [rightDrawerOpen, setRightDrawerOpen] = useState<boolean>(false);
  const currentWorkspace = authState.workspaces.find((ws) => ws._id === authState.defaultWorkspace);

  const handleLeftDrawer = () => {
    setLeftDrawerOpen((prevState: boolean) => !prevState);
  };
  const handleRightDrawer = () => {
    setRightDrawerOpen((prevState: boolean) => !prevState);
  };

  return (
    <AppBar position='static'>
      <Container maxWidth="xl">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleLeftDrawer}
            edge="start"
            sx={{ mr: 2, ...(leftDrawerOpen && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            // noWrap
            component="a"
            href="/"
            sx={{
              // ml: 1,
              // mr: 1,
              // display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            JNC LOGISTICS (S)
          </Typography>
          <div style={{ marginLeft: 'auto', display: 'flex' }}>
            <Box sx={{ flexGrow: 0 }}>
              <Typography variant='caption'>{authState.username} ({currentWorkspace?.name || ''})</Typography>
              <IconButton
                onClick={handleRightDrawer}
                sx={{ ml: 1 }}
                // onClick={() =>}
              >
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Box>
          </div>
        </Toolbar>
      </Container>
      <LeftDrawer
        leftDrawerOpen={leftDrawerOpen}
        setLeftDrawerOpen={setLeftDrawerOpen}
      />
      <RightDrawer
        rightDrawerOpen={rightDrawerOpen}
        setRightDrawerOpen={setRightDrawerOpen}
      />
    </AppBar>
  );
};

interface RightDrawerInterface {
  rightDrawerOpen: boolean;
  setRightDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const RightDrawer: React.FC<RightDrawerInterface> = ({
  rightDrawerOpen,
  setRightDrawerOpen,
}) => {
  const contextApp = useContext(ContextApp);
  const { authDispatch } = contextApp;

  return (
    <SwipeableDrawer
      anchor='right'
      open={rightDrawerOpen}
      onClose={() => {
        setRightDrawerOpen(false);
      }}
      onOpen={() => {
        setRightDrawerOpen(true);
      }}
    >
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Box
          sx={{ width: 250, textAlign: 'center' }}
          role="presentation"
          // onClick={toggleDrawer(anchor, false)}
          // onKeyDown={toggleDrawer(anchor, false)}
        >
          <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
          <Typography
            variant="h6"
            // noWrap
            component="a"
            href="/"
            sx={{
              // ml: 2,
              // display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            JNC Logistics
          </Typography>
          <Divider />

          {/* Select workspace */}
          <SelectWorkspace />
          
          <Divider />
          {/* Account & Settings */}
          <List>
            <ListItem key={'settings'} disablePadding>
              <ListItemButton
                onClick={() => {
                  // navigate('/dashboard');
                }}
              >
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary={'Settings'} />
              </ListItemButton>
            </ListItem>
            <ListItem key={'logout'} disablePadding>
              <ListItemButton
                onClick={async () => {
                  await logout(authDispatch);
                  localStorage.clear();
                  // setRightDrawerOpen(false);
                }}
              >
                <ListItemIcon>
                  {/* <InboxIcon /> */}
                </ListItemIcon>
                <ListItemText primary={'Logout'} />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </div>
    </SwipeableDrawer>
  );
};

interface LeftDrawerInterface {
  leftDrawerOpen: boolean;
  setLeftDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const LeftDrawer: React.FC<LeftDrawerInterface> = ({
  leftDrawerOpen,
  setLeftDrawerOpen,
}) => {
  const navigate = useNavigate();

  const generateMenuBar = () => Modules.map((mod) => (
    <ListItem key={mod.id} disablePadding>
    <ListItemButton
      onClick={() => {
        navigate(mod.route);
        setLeftDrawerOpen(false);
      }}
    >
      <ListItemIcon>
        {mod.icon}
      </ListItemIcon>
      <ListItemText primary={mod.name} />
      </ListItemButton>
    </ListItem>
  ));

  return (
    <SwipeableDrawer
      anchor='left'
      open={leftDrawerOpen}
      onClose={() => {
        setLeftDrawerOpen(false);
      }}
      onOpen={() => {
        setLeftDrawerOpen(true);
      }}
    >
      <Box
        sx={{ width: 250 }}
        role="presentation"
        // onClick={toggleDrawer(anchor, false)}
        // onKeyDown={toggleDrawer(anchor, false)}
      >
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Typography
            variant="h6"
            // noWrap
            component="a"
            href="/"
            sx={{
              // ml: 2,
              // display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            JNC Logistics
          </Typography>
        </div>
        <Divider />
        <List>
          {generateMenuBar()}
          <Divider />
        </List>
      </Box>
    </SwipeableDrawer>
  );
};

// const AppMenuBar: React.FC = () => {
//   const navigate = useNavigate();
//   const contextApp = useContext(ContextApp);
//   const authenticated = isAuthenticated(contextApp);

//   return (
//     <AppBar position='static'>
//       <Container maxWidth="xl">
//         <Toolbar disableGutters>
//           <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
//           <Typography
//             variant="h6"
//             noWrap
//             component="a"
//             href="/"
//             sx={{
//               mr: 2,
//               display: { xs: 'none', md: 'flex' },
//               fontFamily: 'monospace',
//               fontWeight: 700,
//               letterSpacing: '.3rem',
//               color: 'inherit',
//               textDecoration: 'none',
//             }}
//           >
//             LOGO
//           </Typography>

//           {/* Pages */}
//           <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
//             <Button
//               onClick={() => {
//                 navigate('');
//               }}
//               sx={{ my: 2, color: 'white', display: 'block' }}
//             >
//               Home
//             </Button>
//             <Button
//               // onClick={handleCloseNavMenu}
//               sx={{ my: 2, color: 'white', display: 'block' }}
//             >
//               About Us
//             </Button>
//             <Button
//               // onClick={handleCloseNavMenu}
//               sx={{ my: 2, color: 'white', display: 'block' }}
//             >
//               Contact
//             </Button>
//             <Button
//               onClick={() => {
//                 navigate('samples');
//               }}
//               sx={{ my: 2, color: 'white', display: 'block' }}
//             >
//               Samples
//             </Button>
//             <Button
//               onClick={() => {
//                 navigate('dashboard');
//               }}
//               sx={{ my: 2, color: 'white', display: 'block' }}
//             >
//               PRIVATE DASHBOARD
//             </Button>
//           </Box>
          
//           {
//             !authenticated ? (
//               <Button
//                 onClick={() => {
//                   navigate('/login');
//                 }}
//                 sx={{ my: 2, color: 'white', display: 'block' }}
//               >
//                 Login
//               </Button>
//             ) : <ProfileIcon />
//           }
//         </Toolbar>
//       </Container>
//     </AppBar>
//   );
// };


export default AppMenuBar;

const ProfileIcon = () => {
  const navigate = useNavigate();
  const { authState, authDispatch } = useContext(ContextApp);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title='Click to View Actions'>
        <>
          <Typography display='inline'>
            {authState.username}
          </Typography>
          &nbsp;
          &nbsp;
          <IconButton
            // onClick={() => console.log('test')} sx={{ p: 0 }}
            onClick={handleOpenUserMenu}
          >
            <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
          </IconButton>
        </>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem
          onClick={() => {
            navigate('/dashboard');
          }}
        >
          <Typography textAlign="center">My Shipping Dashboard</Typography>
        </MenuItem>
        <MenuItem
        >
          <Typography textAlign="center">My Shipping Address</Typography>
        </MenuItem>
        <MenuItem
        >
          <Typography textAlign="center">My Profile</Typography>
        </MenuItem>
        <MenuItem
          onClick={async () => {
            await logout(authDispatch);
            localStorage.clear();
          }}
        >
          <Typography textAlign="center">Logout</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};
