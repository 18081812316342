import { Dispatch } from 'react';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import FeathersApp from '../feathers/Feathers';
import { ContextAppType } from '../../contexts/ContextApp';
import { AuthenticationStateAction } from '../../reducers/reducerAuthentication';
import { Workspace } from '../feathers/server-interface';

interface Credentials {
  username: string;
  password: string;
}

interface AuthenticationResult {
  accessToken: string,
  user: {
    _id: string;
    username: string;
    workspaces: Array<Workspace>;
    defaultWorkspace: Partial<Workspace> | undefined;
  }
}

export async function authenticate(
  contextApp: ContextAppType,
  params?: Credentials,
): Promise<Error | null> {
  const { authDispatch } = contextApp;
  let authResult: AuthenticationResult;

  try {
    const options = params ? { strategy: 'local', ...params } : undefined;
    authResult = await FeathersApp.authenticate(options) as AuthenticationResult;

    // if (!authResult.user.defaultWorkspace) throw new Error('No workspaces');
    authDispatch({
      type: AuthenticationStateAction.POPULATE_USER,
      payload: {
        ...authResult.user,
        accessToken: authResult.accessToken,
      }
    });
  } catch (error) {
    contextApp.authDispatch({ type: AuthenticationStateAction.LOGOUT });
    return error as Error;
  }
  return null;
};

export async function logout(
  authDispatch: Dispatch<AuthenticationStateAction>,
): Promise<Error | null> {
  try {
    await FeathersApp.logout();
    return null;
  } catch (error) {
    return error as Error;
  } finally {
    authDispatch({ type: AuthenticationStateAction.LOGOUT });
  }
}

export function isAuthenticated(contextApp: ContextAppType): boolean {
  const { authState } = contextApp;
  return !!authState._id;
}

export function getAccessToken(): Promise<string | null> {
  return FeathersApp.authentication.getAccessToken();
}

export async function refreshToken(
  authDispatch: Dispatch<AuthenticationStateAction>,
): Promise<void> {
  const accessToken = await getAccessToken();
  if (accessToken) {
    const { iat = 0, exp = 0 } = jwtDecode<JwtPayload>(accessToken);
    const expiresIn = (exp - iat);
    const now = Date.now();

    if ((iat + expiresIn / 2) * 1000 < now) {
      try {
        const authResult = await FeathersApp.authenticate({ strategy: 'jwt', accessToken });
        authDispatch({
          type: AuthenticationStateAction.POPULATE_USER,
          payload: {
            ...authResult.user,
            accessToken: authResult.accessToken,
          },
        });
      } catch (error) {
        window.location.replace(process.env.REACT_APP_APPCLIENT_URL || "https://japlauto.com/login");
        authDispatch({ type: AuthenticationStateAction.LOGOUT });
      }
    }
  }
}
