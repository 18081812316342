import React, { useRef, useState } from 'react';
import { Card, CardContent, IconButton, Tooltip, Typography } from '@mui/material';
import { Cancel, Add } from '@mui/icons-material';

import PageTitle from '../../layout/PageTitle';

import useFetchShipmentsPaginated from './hooks/useFetchShipmentsPaginated';
import { ShipmentFormInt } from './ShipmentForm';
import { Shipment, User } from '../../feathers/server-interface';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import useDebounce from '../../helpers/hooks/useDebounce';
import SearchBar from '../../utility/SearchBar';

interface ShipmentLeftPanelInterface {
  selectedShipment: Shipment | undefined;
  setSelectedShipment: React.Dispatch<React.SetStateAction<Shipment | undefined>>;
  setShipmentForm: React.Dispatch<React.SetStateAction<ShipmentFormInt>>; 
}

const ShipmentLeftPanel: React.FC<ShipmentLeftPanelInterface> = ({
  selectedShipment,
  setSelectedShipment,
  setShipmentForm,
}) => {
  return (
    <>
      <PageTitle
        title='Shipments'
        rightIcon={(
          <IconButton
            onClick={() => {
              setShipmentForm({
                isOpen: true,
                shipment: undefined,
              });
            }}
          >
            <Tooltip title='Add New'>
              <Add />
            </Tooltip>
          </IconButton>
        )}
      />
      <ShipmentList
        selectedShipment={selectedShipment}
        setSelectedShipment={setSelectedShipment}
      />
    </>
  );
};

export default ShipmentLeftPanel;

interface ShipmentListInterface {
  selectedShipment: Shipment | undefined;
  setSelectedShipment: React.Dispatch<React.SetStateAction<Shipment | undefined>>;
}

const ShipmentList: React.FC<ShipmentListInterface> = ({
  selectedShipment,
  setSelectedShipment,
}) => {
  const virtuoso = useRef<VirtuosoHandle | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearchQuery = useDebounce(searchQuery, 250);
  const searchBarRef: any = useRef();

  const { shipments, loadMore } = useFetchShipmentsPaginated(debouncedSearchQuery, selectedShipment, setSelectedShipment);

  return (
    <>
      <SearchBar
        defaultPlaceholder='Search Shipment'
        defaultValue={debouncedSearchQuery}
        onChange={(value: string) => setSearchQuery(value)}
        // setOnFocus={setDisplayList}
        ref={searchBarRef}
      />
      <div style={{ width: '100%', height: 'calc(100% - 64px)' }}>
        <Virtuoso
          ref={virtuoso}
          data={shipments?.data || []}
          endReached={loadMore}
          totalCount={shipments?.data.length || 0}
          itemContent={(
            index,
            shipment,
          ) => <ShipmentListItem
            shipment={shipment}
            selectedShipment={selectedShipment}
            setSelectedShipment={setSelectedShipment}
          />}
        />
      </div>
    </>
  );
};

interface ShipmentListItemInterface {
  shipment: Shipment;
  selectedShipment: Shipment | undefined;
  setSelectedShipment: React.Dispatch<React.SetStateAction<Shipment | undefined>>;
}

const ShipmentListItem: React.FC<ShipmentListItemInterface> = ({
  shipment,
  selectedShipment,
  setSelectedShipment,
}) => {

  return (
    <Card
      sx={{
        // width: '100%',
        height: '100px',
        marginBottom: '10px',
        cursor: 'pointer',
        borderColor: shipment._id === selectedShipment?._id ? '#1976d2' : '',
      }}
      variant='outlined'
      onClick={() => setSelectedShipment(shipment)}
    >
      <CardContent>
        <Typography variant='h6'>{shipment.contractNumber}</Typography>
      </CardContent>
    </Card>
  );
};
