import { useEffect, useState } from 'react';

import { findClientParcels, parcelFeathersService } from '../../../feathers/services/Parcel.service';
import { ParcelDetailed } from '../../../feathers/server-interface';

const useFetchClientParcels = (clientId: string) => {
  const [clientParcels, setClientParcels] = useState<ParcelDetailed[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(undefined);

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      try {
        const fetchedClientParcels = await findClientParcels(clientId);
        setClientParcels(fetchedClientParcels); 
      } catch (error: any) {
        setError(error.message);
      }
      setIsLoading(false);
    }
    init();
  }, [clientId]);

  useEffect(() => {
    const handleParcelCreate = (createdParcel: ParcelDetailed) => {
      setClientParcels((prevState: ParcelDetailed[]) => {
        return [createdParcel, ...prevState];
      });
    };

    const handleParcelPatch = (patchedParcel: ParcelDetailed) => {
      setClientParcels((prevState: ParcelDetailed[]) => prevState.map((prevParcel: ParcelDetailed) => {
        if (prevParcel._id === patchedParcel._id) return patchedParcel;
        return prevParcel;
      }))
    };

    parcelFeathersService.on('created', handleParcelCreate);
    parcelFeathersService.on('patched', handleParcelPatch);

    return () => {
      parcelFeathersService.removeListener('created', handleParcelCreate);
      parcelFeathersService.removeListener('patched', handleParcelPatch);
    }
  }, []);

  return {
    clientParcels,
    isLoading,
    error,
  };
};

export default useFetchClientParcels;