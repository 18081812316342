import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import LeftRightPanel from '../../layout/LeftRightPanel';
import OrderLeftPanel from './OrderLeftPanel';
import OrderRightPanel from './OrderRightPanel';
import OrderForm, { OrderFormInt } from './OrderForm';
import { getOrder } from '../../feathers/services/Order.service';
import { Order as OrderInt } from '../../feathers/server-interface';

const Order: React.FC = () => {
  const { state } = useLocation();

  const [selectedOrder, setSelectedOrder] = useState<OrderInt | undefined>(undefined);
  const [orderForm, setOrderForm] = useState<OrderFormInt>({
    isOpen: false,
    order: undefined,
  });

  useEffect(() => {
    if (state) {
      const orderId = state.shipment as unknown as string;
      const getSelectedOrder = async () => {
        const foundOrder = await getOrder(orderId);
        setSelectedOrder(foundOrder);
      }
      getSelectedOrder();
    }
  }, [state]);

  return (
    <>
      <LeftRightPanel
        leftPanelComponent={(
          <OrderLeftPanel
            selectedOrder={selectedOrder}
            setSelectedOrder={setSelectedOrder}
            setOrderForm={setOrderForm}
          />
        )}
        rightPanelComponent={(
          <OrderRightPanel
            selectedOrder={selectedOrder}
            setSelectedOrder={setSelectedOrder}
            setOrderForm={setOrderForm}
          />
        )}
      />
      <OrderForm
        orderForm={orderForm}
        setOrderForm={setOrderForm}
      />
    </>
  );
};

export default Order;
