import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Client from './Client';

const ClientViewer: React.FC = (): any => (
  <div style={{ width: '100%', height: '100%' }}>
    <Routes>
      <Route
        path='/'
        element={<Client />}
      />
    </Routes>
  </div>
);

export default ClientViewer;
