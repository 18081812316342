import React, { useState } from 'react';

import LeftRightPanel from '../../layout/LeftRightPanel';
import AppManagementLeftPanel from './AppManagementLeftPanel';
import AppManagementRightPanel from './AppManagementRightPanel';

export interface ManagementItemInterface {
  id: string;
  title: string;
}

const AppManagement: React.FC = () => {
  const [managementItems] = useState<ManagementItemInterface[]>([{
    id: 'workspaces',
    title: 'Workspaces',
  }, {
    id: 'users',
    title: 'Users',
  }]);
  const [selectedManagementItem, setSelectedManagementItem] = useState<ManagementItemInterface | undefined>(undefined);

  return (
    <LeftRightPanel
      leftPanelComponent={(
        <AppManagementLeftPanel
          managementItems={managementItems}
          selectedManagementItem={selectedManagementItem}
          setSelectedManagementItem={setSelectedManagementItem}
        />
      )}
      rightPanelComponent={(
        <AppManagementRightPanel
          selectedManagementItem={selectedManagementItem}
          setSelectedManagementItem={setSelectedManagementItem}
        />
      )}
    />
  );
};

export default AppManagement;
