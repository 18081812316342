import { Paginated } from '@feathersjs/feathers';
import { paramsForServer } from 'feathers-hooks-common';

import FeathersApp, { Service } from '../Feathers';
import { Order } from '../server-interface';

export const orderFeathersService: any = FeathersApp.service(Service.Order);

export const getOrder = (orderId: string): Promise<Order> => (
  orderFeathersService.get(orderId)
);

export const createOrder = (data: Omit<Order, '_id'>): Promise<Order> => (
  orderFeathersService.create(data)
);

export const patchOrder = (orderId: string, data: Omit<Order, '_id'>): Promise<Order> => (
  orderFeathersService.patch(orderId, data)
);

export const findOrders = (): Promise<Order[]> => (
  orderFeathersService.find(paramsForServer({
    paginate: false,
    query: {
      $sort: {
        'createInfo.dateTime': -1,
      },
    },
  }))
);

export const findOrdersPaginated = (
  skip: number,
  searchQuery: string,
): Promise<Paginated<Order>> => (
  orderFeathersService.find({
    query: {
      $skip: skip,
      $sort: {
        'createInfo.dateTime': -1,
      },
      $or: [
        { name: { $regex: searchQuery, $options: 'i' } },
      ].filter((item) => item),
    },
  })
);
