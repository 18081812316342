import { useEffect, useState } from 'react';
import { workplaceFeathersService, workplaceFind } from '../../../../feathers/services/Workspace.service';
import { Workspace } from '../../../../feathers/server-interface';

const useFetchWorkspaces = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>();
  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);

  // Init
  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      try {
        const fetchedWorkspaces = await workplaceFind();
        setWorkspaces(fetchedWorkspaces);
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };
    init();
  }, []);

  // Listener
  useEffect(() => {
    const handleWorkspaceCreate = (createdWorkspace: Workspace) => {
      setWorkspaces((prevState: Workspace[]) => [createdWorkspace, ...prevState]);
    };
    const handleWorkspacePatch = (patchedWorkspace: Workspace) => {
      setWorkspaces((prevState: Workspace[]) => prevState.map((state: Workspace) => {
        if (state._id === patchedWorkspace._id) return patchedWorkspace;
        return state;
      }))
    };
    const handleWorkspaceRemoved = (removedWorkspace: Workspace) => {
      setWorkspaces((prevState: Workspace[]) => prevState.filter((state) => state._id === removedWorkspace._id));
    };

    workplaceFeathersService.on('created', handleWorkspaceCreate);
    workplaceFeathersService.on('patched', handleWorkspacePatch);
    workplaceFeathersService.on('removed', handleWorkspaceRemoved);

    return () => {
      workplaceFeathersService.removeListener('created', handleWorkspaceCreate);
      workplaceFeathersService.removeListener('patched', handleWorkspacePatch);
      workplaceFeathersService.removeListener('removed', handleWorkspaceRemoved);
    }
  }, []);

  return {
    workspaces,
    isLoading,
    error,
  };
};

export default useFetchWorkspaces;
